import { Input, Textarea } from "components/Input";
import { errorToast } from "helpers/Toast";
import {
  addBuyYourselfToOrder,
  addItemToOrder,
  createBuyYourself,
  createMedicine,
  createOrder,
} from "queries/orderQueries";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import "tippy.js/dist/tippy.css";
import BuyYourselfNoticeMessages from "components/AddItems/BuyYourselfNoticeMessages";

import { Button, CancelButton } from "components/Button";
import { useForm } from "react-hook-form";
export default function MedicineAddItemsInputs() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  // console.log("location.state");
  // console.log(location.state);
  const queryClient = useQueryClient();
  const { orderId } = useParams();
  const [selectedItemType, setSelectedItemType] = useState(
    location.state?.itemType
  );

  const resetHandler = () => {
    reset();
    setSelectedItemType("");
  };

  const mutationSuccessHandler = (data) => {
    console.log(data);
    reset();
    setSelectedItemType("");
    queryClient.invalidateQueries("orders");
    queryClient.invalidateQueries("allOrders");
    // setAddItem(false);
    navigate(`/user/medicine/${data._id}`);
  };

  const createMedicineMutation = useMutation(createMedicine, {
    onSuccess: (data) => {
      mutationSuccessHandler(data);
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });

  // let existingOrderData = data && data.filter((item) => !item.isMedicine);
  const onSubmitHandler = (role, formData) => {
    formData["orderType"] = "medicine";
    createMedicineMutation.mutate(formData);
  };

  const onSubmit = async (formData) => {
    console.log("formData");
    console.log(formData);
    // tertiary function to facilitate push and put request based on condition
    formData = { ...formData, itemType: "default" };
    onSubmitHandler(selectedItemType, formData);
  };

  const convertToWeBuyButtonHandler = () => {
    reset();
    setSelectedItemType("weBuy");
  };

  return (
    <div className=" container mx-auto">
      <h3 className="h3 mb-4 text-center">Medicine</h3>

      {orderId !== null && orderId !== undefined ? (
        <Outlet />
      ) : (
        <div className="flex justify-center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex lg:min-w-[800px]">
              <div className="mr-6">
                <Input
                  title="Patient Name"
                  inputName="name"
                  register={register}
                  errors={errors}
                  required
                  maxLength={100}
                />
              </div>
            </div>
            <div className="flex lg:min-w-[800px]">
              <div className="mr-6">
                <Textarea
                  title="Description"
                  inputName="description"
                  register={register}
                  errors={errors}
                  required
                  maxLength={4000}
                />
              </div>

              <div className="flex flex-col">
                <Input
                  title="Prescription Images"
                  inputName="prescriptionImages"
                  inputType="file"
                  register={register}
                  errors={errors}
                  multiple
                />
              </div>
            </div>

            <div className="flex lg:min-w-[800px] mb-4">
              <div className="flex flex-col mr-6">
                <Input
                  title="Passport Image"
                  inputName="passportImage"
                  inputType="file"
                  register={register}
                  errors={errors}
                  required
                />
              </div>

              <div className="flex flex-col">
                <Input
                  title="Visa Image"
                  inputName="visaImage"
                  inputType="file"
                  register={register}
                  errors={errors}
                  required
                />
              </div>
            </div>
            <div className="flex">
              {/* <CancelButton
            className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
            onClick={() => {
              reset();
              setSelectedItemType("");
              setAddItem(false);
            }}
          >
            cancel
          </CancelButton> */}

              <Button type="submit" disabled={createMedicineMutation.isLoading}>
                {createMedicineMutation.isLoading ? "Processing..." : "Save"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
