import Loading from "components/Loading";
import SupportButton from "components/SupportButton";
import { successToast } from "helpers/Toast";
import {
  adminAddReviewDetails,
  adminAddTrackingNumber,
  adminConfirmReviewed,
  getOrders,
  userAckonwledgedReviewed,
  userLogisticsPayment,
} from "queries/orderQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function ReadyToShip() {
  const role = localStorage.getItem("userRole");
  const queryClient = useQueryClient();
  const [itemId, setItemId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isPriceModalOpen, setPriceModalOpen] = useState(false);
  const [isConfirmReviewedOpen, setConfirmReviewedOpen] = useState(false);
  const [reviewData, setReveiwData] = useState();
  const [paymentGatewayOpen, setPaymentGatewayOpen] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState();

  let isReviewedVerifier = 0;
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm();
  const { data, isLoading, isError, error } = useQuery(
    ["orders", "readyToShip"],
    () => getOrders("readyToShip")
  );

  const mutation = useMutation(adminAddTrackingNumber, {
    onSuccess: (data) => {
      console.log(data);
      reset();
      setOrderId("");
      setItemId("");
      setPaymentAmount("");
      setPriceModalOpen(false);
      queryClient.invalidateQueries("orders");
    },
  });

  const confirmMutation = useMutation(adminConfirmReviewed, {
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries("orders");
    },
  });

  const userAcknowledgedMutation = useMutation(userLogisticsPayment, {
    onSuccess: (data) => {
      console.log(orderId);
      console.log(data);
      setPaymentGatewayOpen(false);
      successToast(
        "Successfully completed Payment. The items will be Shipped soon"
      );
      queryClient.invalidateQueries("orders");
    },
    onError: () => {
      console.log(orderId);
    },
  });

  const onCancelHandler = () => {
    reset();
    setOrderId("");
    setItemId("");
    setPaymentAmount("");
    setPriceModalOpen(false);
  };

  const detailsHandler = (orderId, itemId) => {
    setOrderId(orderId);
    setPriceModalOpen(true);
  };
  const onSubmit = (formData) => {
    console.log(formData);
    console.log("OrderId and ItemID");
    console.log(formData);
    console.log(orderId);
    console.log(itemId);
    const newData = { ...formData, orderId };
    console.log(newData);
    mutation.mutate(newData);
  };

  const verifier = (price) => {
    // console.log(price);
    if (price) {
      isReviewedVerifier += 1;
    }
    // console.log(isReviewedVerifier);
  };
  const resetVerifier = () => {
    isReviewedVerifier = 0;
  };
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    console.log(error);
    return <div>Something went wrong!!!</div>;
  }

  return (
    <>
      {paymentGatewayOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Payment Gateway</h1>
            <div>
              <div className="mb-6">
                The total price to procure the items are ₹{paymentAmount}
              </div>
              <button
                className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                onClick={() => setPaymentGatewayOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-700 font-bold py-2 text-white px-6 rounded-md m-auto"
                onClick={() => userAcknowledgedMutation.mutate(orderId)}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      )}
      {isPriceModalOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Add Details</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col ">
                <label htmlFor="trackingNumber" className="text-left">
                  Tracking Number
                </label>
                <input
                  className="input"
                  type="text"
                  placeholder="Tracking Number "
                  {...register("trackingNumber", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.trackingNumber && (
                  <span className="text-red-500">
                    {errors.trackingNumber.message}
                  </span>
                )}
              </div>

              <div className="flex flex-col ">
                <label htmlFor="courier" className="text-left">
                  Courier
                </label>
                <input
                  className="input"
                  type="text"
                  placeholder="Name of courier "
                  {...register("courier", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.courier && (
                  <span className="text-red-500">{errors.courier.message}</span>
                )}
              </div>

              <div>
                <button
                  className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
                <button className="bg-blue-500 px-4 py-2 rounded-md">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* TODO: Create this flow after asking if it is required for the usecase and what details should be displayed in this */}
      {/* {isConfirmReviewedOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Confirm Review Details</h1>
            <div>{reviewData.map((data) => (

<div>

            ))}</div>

            <div>
              <button
                className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                onClick={onCancelHandler}
              >
                Cancel
              </button>
              <button className="bg-blue-500 px-4 py-2 rounded-md">
                Submit
              </button>
            </div>
          </div>
        </div>
      )} */}
      <div>
        {/* delete Modal */}

        {data.length !== 0 ? (
          <div>
            {data.map((orders, index) => (
              <div className="mt-6" key={orders._id}>
                <div className="hidden">{(isReviewedVerifier = 0)}</div>

                <div className="flex">
                  <h3 className="font-medium mr-4">Order ID:</h3>
                  <p>{orders.uniqueOrderId}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium mr-4">User Email:</h3>
                  <p>{orders.userEmail}</p>
                </div>
                <table className="table-auto border border-rounded-xl">
                  <thead className="bg-gray-300">
                    <tr>
                      <th className="px-4">
                        {orders.isMedicine ? "Name" : "Product Name"}
                      </th>
                      {orders.isMedicine ? null : (
                        <th className="px-4">Quantity</th>
                      )}

                      <th className="px-4"></th>
                    </tr>
                  </thead>
                  <tbody key={orders._id} className="mb-8 border">
                    {orders.items
                      .filter(
                        (item) =>
                          item.isProcurable &&
                          (orders.orderType === "buyYourself" ||
                            orders.orderType === "inHouse" ||
                            item.userAcknowledge)
                      )
                      .map((product) => (
                        <tr key={product._id}>
                          <td className="text-center px-6">{product.name}</td>
                          <td className="text-center px-6">
                            {product.procurableQuantity}
                          </td>

                          {role === "user" ? (
                            orders.isReviewedEmailSent === true ? (
                              <></>
                            ) : null
                          ) : (
                            <></>
                          )}
                          <td>
                            <div
                              className={`text-xs text-center p-2 rounded-full m-2 +
                                  ${
                                    product.itemType === "cart"
                                      ? "bg-orange-100"
                                      : "bg-teal-100"
                                  }`}
                            >
                              {product.itemType === "cart"
                                ? "In House"
                                : orders.orderType}
                            </div>
                          </td>
                          {verifier(product.price)}
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div>The weight of the package is : {orders.weight} kg</div>
                <div>
                  The shipping cost to <i>{orders.address.country}</i> is ₹
                  {orders.totalShippingCost}{" "}
                  {orders.isDiscount && (
                    <>
                      <p>
                        You have availed a discount of ₹{orders.discountAmount}
                      </p>
                      <p>
                        The payable amount is: ₹
                        {orders.totalShippingCost - orders.discountAmount}
                      </p>
                    </>
                  )}
                </div>

                {role === "user" ? (
                  orders.isLogisticsChargePaid ? (
                    <div className="text-blue-600 mt-2">
                      Order will be shipped soon
                    </div>
                  ) : (
                    <>
                      <SupportButton />
                      <button
                        className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                        onClick={() => {
                          setOrderId(orders._id);
                          setPaymentAmount(
                            orders.totalShippingCost -
                              (orders.isDiscount ? orders.discountAmount : 0)
                          );
                          setPaymentGatewayOpen(true);
                        }}
                      >
                        Pay Shipping Cost
                      </button>
                    </>
                  )
                ) : null}

                {role !== "user" ? (
                  orders.isLogisticsChargePaid ? (
                    <button
                      className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                      onClick={() => {
                        detailsHandler(orders._id);
                        // confirmMutation.mutate(orders._id);
                      }}
                    >
                      Add Tracking Number
                    </button>
                  ) : (
                    // <div className="text-green-500">
                    //   Package and Ship the order.
                    // </div>
                    <div className="text-red-200">Awaiting Payment</div>
                  )
                ) : null}
              </div>
            ))}

            {/* <div className="mt-6">
              <button
                className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-8"
                onClick={() => deleteOrderHandler(orders._id)}
              >
                Delete Order
              </button>
              <button
                className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8"
                onClick={() => submitOrderMutation.mutate(orders._id)}
              >
                Submit Order
              </button>
            </div> */}
          </div>
        ) : (
          <div className="">No Orders in Ready to Ship</div>
        )}
      </div>
    </>
  );
}
