import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { Button } from "components/Button";
import Loading from "components/Loading";
import { getAllOrders } from "queries/orderQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import OrdersTable from "./OrdersTable";

const buyingModelData = [
  { name: "Buy Yourself", link: "/user/buy-yourself" },
  { name: "We Buy 4 U", link: "/user/we-buy-4-u" },
  { name: "medicine", link: "/user/medicine" },
  { name: "Customized Buy", link: "/user/customized-buy" },
  { name: "In House Products", link: "/user/in-house" },
];

// const ProductActions = ({ product }) => {
//     return <div>Product Actions as mentioned</div>;
// }

export default function UserOrders() {
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useQuery(
    "allOrders",
    getAllOrders
  );
  console.log(data && data);
  const [openAddItem, setAddItem] = useState(false);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    console.log(error);
    return <div>Something went wrong!!!</div>;
  }

  return (
    <div className="flex justify-center">
      <div className="max-w-[90vw]">
        {openAddItem && (
          <Dialog
            className="flex flex-wrap gap-y-8"
            onDismiss={() => setAddItem(false)}
          >
            {buyingModelData.map((item) => (
              <Button onClick={() => navigate(item.link)} key={item._id}>
                {item.name}
              </Button>
            ))}
          </Dialog>
        )}
        <Button
          onClick={() => {
            setAddItem(!openAddItem);
          }}
        >
          Add Order
        </Button>

        <div className="">
          <div>
            {data.filter((filteredData) => filteredData.orderStatus === "draft")
              .length > 0 && (
              <>
                <h2 className="h2 mt-4">Bag</h2>
                <OrdersTable
                  data={data.filter(
                    (filteredData) => filteredData.orderStatus === "draft"
                  )}
                />
              </>
            )}
          </div>
          <div className="">
            <h2 className="h2 mt-4">Orders</h2>
            <OrdersTable
              data={data.filter(
                (filteredData) => filteredData.orderStatus !== "draft"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
