import { itemPut } from "queries/orderQueries";
import React, { useState, lazy } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import Gallery from "react-grid-gallery";

export default function ItemDetails({
  product,
  orderId,
  isReviewedEmailSent,
  orderStatus,
  orderType,
  isMedicine,
}) {
  // const mutation =

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const role = localStorage.getItem("userRole");
  const [query, setQuery] = useState("");
  const [addImageView, setAddImageView] = useState(false);

  const queryClient = useQueryClient();

  const mutation = useMutation(itemPut, {
    onSuccess: (data) => {
      reset();
      setAddImageView("");
      console.log(data);
      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("orders");
    },
  });

  const imageArrayHandler = (val, images, imageArrayKey) => {
    const imageArray = images.filter((item) => item !== val && item);
    console.log(imageArray);
    const splitVal = imageArray.length > 0 && imageArray[0].split("/");
    console.log(splitVal);
    const data = { orderId, itemId: product._id };
    data[`${imageArrayKey}`] = imageArray;
    return data;
  };

  const deleteImage = (val) => {
    if (orderStatus === "draft") {
      if (isMedicine) {
        const data = imageArrayHandler(
          val,
          product.prescriptionImages,
          "prescriptionImages"
        );

        mutation.mutate({ data, query: "medicine" });
        return;
      }
      const data = imageArrayHandler(val, product.userImages, "userImages");

      mutation.mutate({ data, query: "deleteImage" });
    } else {
      const data = imageArrayHandler(
        val,
        product.procuredImages,
        "procuredImages"
      );
      mutation.mutate({ data, query: "deleteProcuredImage" });
    }
  };

  const onCancelHandler = () => {
    reset();
    setAddImageView("");
  };
  const replaceImage = (formData) => {
    console.log(formData);
    const data = { orderId, itemId: product._id };
    console.log("formData.arrayKeyValue");
    console.log(formData.arrayKeyValue);
    // object gets the previous data present for key value pair and merges with the present data

    console.log(data);
    mutation.mutate({
      data,
      processing: {
        images: formData[`${formData.arrayKeyValue}`],
        imagesKey: formData.arrayKeyValue,
      },
      query: "replaceImage",
    });
  };

  const onSubmit = (formData) => {
    console.log(formData);
    const data = { orderId, itemId: product._id };
    console.log("formData.arrayKeyValue");
    console.log(formData.arrayKeyValue);
    // object gets the previous data present for key value pair and merges with the present data
    // if (localStorage.getItem("userRole") !== "user") {
    //   data["userId"] = product.userId;
    // }

    data[`${formData.arrayKeyValue}`] = [
      ...product[`${formData.arrayKeyValue}`],
    ];
    console.log("data");
    console.log(data);
    mutation.mutate({
      data,
      processing: {
        images: formData[`${formData.arrayKeyValue}`],
        imagesKey: formData.arrayKeyValue,
      },
      query: "addImage",
    });
  };

  const ViewAndAddMultipleImage = ({
    title,
    imagesArray,
    imagesArrayKey,
    isViewOnly,
  }) => {
    return (
      <>
        <div>
          <h3 className="font-bold">{title}</h3>
          <div className="flex flex-wrap">
            {imagesArray?.length > 0
              ? imagesArray.map((image, index) => (
                  <div className="group relative mr-4 mb-4" key={index}>
                    <img src={image} width={200} height={200} />
                    {isViewOnly === "false" && (
                      <button
                        className="hidden group-hover:block absolute right-1 top-1 rounded-full bg-white w-6 h-6"
                        onClick={() => deleteImage(image)}
                      >
                        <MdOutlineClose className="mx-auto" />
                      </button>
                    )}
                  </div>
                ))
              : "There are no images to display"}
          </div>
        </div>
        <div>
          {isViewOnly === "false" ? (
            addImageView === imagesArrayKey ? (
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col">
                    <input
                      className="border-none"
                      type="file"
                      accept="image/png,image/jpeg,image/jpg"
                      multiple
                      {...register(imagesArrayKey)}
                    />
                    {errors[imagesArrayKey] && (
                      <span className="text-red-500">
                        {errors[imagesArrayKey].message}
                      </span>
                    )}
                  </div>
                  <input
                    className="hidden"
                    type="text"
                    value={imagesArrayKey}
                    {...register("arrayKeyValue")}
                  />
                  <div className="flex">
                    <button
                      className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
                      onClick={onCancelHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
                      type="submit"
                      disabled={mutation.isLoading}
                    >
                      {mutation.isLoading ? "processing..." : `Upload ${title}`}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <button
                className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
                onClick={() => setAddImageView(imagesArrayKey)}
              >
                Add {title}
              </button>
            )
          ) : null}
        </div>
      </>
    );
  };

  const ViewAndAddSingleImage = ({ title, image, imageKey, isViewOnly }) => {
    // console.log("isViewOnly");

    // console.log(isViewOnly);
    return (
      <>
        <div>
          <h3 className="font-bold">{title}</h3>
          <div className="flex flex-wrap">
            {image !== "" || image === undefined || image === null ? (
              <div className="group relative mr-4 mb-4">
                <img src={image} width={200} height={200} />
              </div>
            ) : (
              "There are no image to display"
            )}
          </div>
        </div>
        <div>
          {isViewOnly === "false" ? (
            addImageView === imageKey ? (
              <div>
                <form onSubmit={handleSubmit(replaceImage)}>
                  <div className="flex flex-col">
                    <input
                      className="border-none"
                      type="file"
                      accept="image/png,image/jpeg,image/jpg"
                      {...register(imageKey, {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                    {errors[imageKey] && (
                      <span className="text-red-500">
                        {errors[imageKey].message}
                      </span>
                    )}
                  </div>
                  <input
                    className="hidden"
                    type="text"
                    value={imageKey}
                    {...register("arrayKeyValue")}
                  />
                  <div className="flex">
                    <button
                      className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
                      onClick={onCancelHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
                      type="submit"
                      disabled={mutation.isLoading}
                    >
                      {mutation.isLoading
                        ? "processing..."
                        : `Replace ${title}`}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <button
                className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
                onClick={() => setAddImageView(imageKey)}
              >
                Replace {title}
              </button>
            )
          ) : null}
        </div>
      </>
    );
  };

  const ViewVerifier = ({
    allowedOrderStatus,
    allowedItemStatus,
    allowedItemType,
    condition,
    children,
  }) => {
    const verifiedData =
      allowedItemType.includes(orderType) &&
      allowedOrderStatus.includes(orderStatus);
    console.log(
      "Verified data",
      verifiedData,
      orderStatus,
      allowedOrderStatus.includes(orderStatus),
      orderType,
      allowedItemType.includes(orderType)
    );
    const isUser = role === "user";

    const verifyRole =
      role === isUser ? (condition ? verifiedData : null) : verifiedData;
    return <>{verifyRole ? children : null}</>;
  };
  const isViewOnlyValue =
    orderStatus === "draft" ? "false" : role === "user" ? "true" : "false";

  return (
    <div className="mx-auto">
      <div>
        <h3 className="font-bold">Description</h3>
        <p>{product.description}</p>
      </div>

      <ViewVerifier
        allowedItemType={["medicine", "weBuy"]}
        allowedOrderStatus={[
          "review",
          "procuring",
          "readyToShip",
          "shipped",
          "delivered",
          "return",
        ]}
        condition={isReviewedEmailSent}
      >
        <ViewAndAddMultipleImage
          title="recieved products"
          imagesArray={product.procuredImages}
          imagesArrayKey="procuredImages"
          isViewOnly={isViewOnlyValue}
        />
      </ViewVerifier>
      <ViewVerifier
        allowedItemType={["buyYourself"]}
        allowedOrderStatus={[
          "draft",
          "review",
          "procuring",
          "readyToShip",
          "shipped",
          "delivered",
          "return",
        ]}
        condition={true}
      >
        <ViewAndAddMultipleImage
          title="Invoice Images"
          imagesArray={product.invoiceImages}
          imagesArrayKey="invoiceImages"
          isViewOnly={isViewOnlyValue}
        />
      </ViewVerifier>

      <ViewVerifier
        allowedItemType={["medicine"]}
        allowedOrderStatus={[
          "review",
          "procuring",
          "readyToShip",
          "shipped",
          "delivered",
          "return",
        ]}
        condition={isReviewedEmailSent}
      >
        <ViewAndAddMultipleImage
          title="New Prescription Images"
          imagesArray={product.latestPrescriptionImages}
          imagesArrayKey="latestPrescriptionImages"
          isViewOnly={isViewOnlyValue}
        />
      </ViewVerifier>
      <ViewVerifier
        allowedItemType={["medicine", "weBuy"]}
        allowedOrderStatus={[
          "review",
          "procuring",
          "readyToShip",
          "shipped",
          "delivered",
          "return",
        ]}
        condition={isReviewedEmailSent}
      >
        <ViewAndAddMultipleImage
          title="Receipt Images"
          imagesArray={product.recieptImages}
          imagesArrayKey="recieptImages"
          isViewOnly={isViewOnlyValue}
        />{" "}
      </ViewVerifier>
      <ViewVerifier
        allowedItemType={["medicine"]}
        allowedOrderStatus={[
          "draft",
          "review",
          "procuring",
          "readyToShip",
          "shipped",
          "delivered",
          "return",
        ]}
        condition={true}
      >
        <ViewAndAddMultipleImage
          title="Prescription Images"
          imagesArray={product.prescriptionImages}
          imagesArrayKey="prescriptionImages"
          isViewOnly={isViewOnlyValue}
        />{" "}
      </ViewVerifier>
      <ViewVerifier
        allowedItemType={["medicine"]}
        allowedOrderStatus={[
          "draft",
          "review",
          "procuring",
          "readyToShip",
          "shipped",
          "delivered",
          "return",
        ]}
        condition={true}
      >
        <ViewAndAddSingleImage
          title="Passport Front Image"
          image={product.passportImage}
          imageKey="passportImage"
          isViewOnly={isViewOnlyValue}
        />{" "}
      </ViewVerifier>
      <ViewVerifier
        allowedItemType={["medicine"]}
        allowedOrderStatus={[
          "draft",
          "review",
          "procuring",
          "readyToShip",
          "shipped",
          "delivered",
          "return",
        ]}
        condition={true}
      >
        <ViewAndAddSingleImage
          title="Visa Image"
          image={product.visaImage}
          imageKey="visaImage"
          isViewOnly={isViewOnlyValue}
        />{" "}
      </ViewVerifier>
      <ViewVerifier
        allowedItemType={["weBuy"]}
        allowedOrderStatus={[
          "draft",
          "review",
          "procuring",
          "readyToShip",
          "shipped",
          "delivered",
          "return",
        ]}
        condition={true}
      >
        <ViewAndAddMultipleImage
          title="Images"
          imagesArray={product.userImages}
          imagesArrayKey="userImages"
          isViewOnly={isViewOnlyValue}
        />{" "}
      </ViewVerifier>
    </div>
  );
}
