import React from "react";
import ReactDom from "react-dom";

const OrderPaymentModal = ({
  order,
  paymentAmount,
  paymentAmountWithGst,
  setPaymentGatewayOpen,
  userAcknowledgedMutation,
}) => {
  return ReactDom.createPortal(
    <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
      <div className="bg-white p-6">
        <h1 className="mb-8 ">Payment Gateway</h1>
        <div>
          <div>
            {order.orderType === "medicine" ? null : (
              <table className="border">
                <thead className="border border-bottom-1">
                  <tr>
                    <th className="mx-2 px-4">Name</th>
                    <th className="mx-2 px-4">Quantity</th>
                    <th className="mx-2 px-4">Price *</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items
                    .filter((val) => val.isProcurable && val.userAcknowledge)
                    .map((item) => (
                      <tr>
                        <td className="mx-2 px-4">{item.name}</td>
                        <td className="mx-2 px-4">{item.procurableQuantity}</td>
                        <td className="mx-2 px-4">{item.totalPrice}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="mb-6">
            The total price to procure the items are ₹{paymentAmount}
          </div>
          <div className="text-[10px) text-left">
            * Inclusive of service charges and GST
          </div>
          <button
            className="bg-gray-500 px-4 py-2 rounded-md mr-6"
            onClick={() => setPaymentGatewayOpen(false)}
          >
            Cancel
          </button>
          <button
            className="bg-blue-700 font-bold py-2 text-white px-6 rounded-md m-auto"
            onClick={() => {
              const data = {
                orderId: order._id,
                orderCost: paymentAmount,
                orderCostWithGst: paymentAmountWithGst,
              };
              userAcknowledgedMutation.mutate(data);
            }}
          >
            Pay Now
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default OrderPaymentModal;
