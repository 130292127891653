import {
  Link,
  Outlet,
  useNavigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import { logout } from "helpers/storageHelper";
import useUserStore from "stores/UserStore";
import { useState } from "react";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const role = useUserStore((state) => state.role);
  const [viewAccountDetailsOpen, setViewAccountDetailsOpen] = useState(false);
  const LogoutHandler = () => {
    logout();
    navigate("/", { replace: true });
  };
  const { pathname } = location;
  const splitValue = pathname.split("/");
  console.log("splitValue");

  console.log(splitValue);
  const linkActive = "font-bold text-xl text-cyan-300 underline scale-105  ";
  const linkInactive = "font-bold text-xl text-white hover:text-cyan-500";

  return (
    <div className="flex">
      {role !== "user" && (
        <div className="dashboard-sidebar-colors flex flex-row rounded-tr-xl rounded-br-xl">
          <div className="w-44 p-6 pt-12 mt-8">
            <menu className="fixed">
              <li className="mb-6">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkActive : linkInactive
                  }
                  to="/dashboard"
                >
                  Dashboard
                </NavLink>
              </li>
              <li className="mb-6">
                <NavLink
                  className={() =>
                    splitValue.includes("orders") ? linkActive : linkInactive
                  }
                  to={role === "user" ? "/user/orders" : "/orders/review"}
                >
                  Orders
                </NavLink>
              </li>
              <li className="mb-6">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkActive : linkInactive
                  }
                  to="/history"
                >
                  <span className="whitespace-nowrap">Order History</span>
                </NavLink>
              </li>
              {(role === "employee" || role === "admin") && (
                <>
                  <li className="mb-6">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkActive : linkInactive
                      }
                      to="/categories"
                    >
                      <span className="whitespace-nowrap">Categories</span>
                    </NavLink>
                  </li>
                  <li className="mb-6">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkActive : linkInactive
                      }
                      to="/admin-products"
                    >
                      <span className="whitespace-nowrap">Products</span>
                    </NavLink>
                  </li>
                </>
              )}
            </menu>
          </div>
        </div>
      )}
      <div className=" p-6 bg-[#F0FEFC] text-[#0A2B42] flex-grow min-h-screen">
        <div className="container mx-auto">
          <div className="flex flex-row justify-end mb-12">
            <menu className="flex flex-row">
              <li className="mr-6">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkActive : `${linkInactive} text-[#0A2B42]`
                  }
                  to="/"
                >
                  Home
                </NavLink>{" "}
              </li>
              <li className="mr-6">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkActive : `${linkInactive} text-[#0A2B42]`
                  }
                  to="/user/in-house"
                >
                  Products
                </NavLink>{" "}
              </li>
              {role === "user" && (
                <li className="mr-6">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? linkActive : `${linkInactive} text-[#0A2B42]`
                    }
                    to="/user/orders"
                  >
                    Orders
                  </NavLink>{" "}
                </li>
              )}
              <li className="mr-6">
                <button className="relative group">
                  <div className="rounded-full bg-green-400 w-8 h-8 flex justify-center items-center">
                    <span className="">
                      {localStorage.getItem("name").charAt(0)}
                    </span>
                  </div>
                  <div className="absolute right-0 hidden p-4 mt-2 bg-indigo-100 flex-col gap-4 group-focus:flex">
                    <div className="flex gap-4 items-center">
                      <div className="rounded-full bg-green-400 w-12 h-12 flex justify-center items-center">
                        <span className="">
                          {localStorage.getItem("name").charAt(0)}
                        </span>
                      </div>
                      <div>
                        <p className="text-left mb-1">
                          {localStorage.getItem("name")}
                        </p>
                        <p className="text-left">
                          {localStorage.getItem("email")}
                        </p>{" "}
                      </div>
                    </div>

                    <p
                      onClick={() => navigate("/profile")}
                      className="whitespace-nowrap text-left"
                    >
                      My Profile
                    </p>
                    <p onClick={LogoutHandler} className="text-left">
                      Logout
                    </p>
                  </div>
                </button>
              </li>
            </menu>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
