import { GoogleLogin } from "@react-oauth/google";
import { SignUpButton } from "components/Button";
import { saveToken, saveUser } from "helpers/storageHelper";
import { googleLogin, login } from "queries/authQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useMutation } from "react-query";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";
import Logo from "../../assets/Logo.jpeg";

export default function Login() {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const role = useUserStore((state) => state.role);
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    role === "user"
      ? location.state?.from?.pathname
        ? location.state?.from?.pathname
        : "/user/orders"
      : "/dashboard";
  // const stateData = location.st

  const [showPassword, setShowPassword] = useState(false);
  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const googleMutation = useMutation(googleLogin, {
    onSuccess: (data) => {
      console.log(data);
      console.log("successfully Logged In");
      saveToken(data.accessToken, data.refreshToken);
      saveUser(data.user);
      navigate(from, { replace: true, state: location.state });
    },
  });

  const mutation = useMutation(login, {
    onSuccess: (data) => {
      console.log(data);
      console.log("successfully Logged In");
      saveToken(data.accessToken, data.refreshToken);
      saveUser(data.user);
      console.log(from);
      navigate(from, { replace: true, state: location.state });
    },
  });

  const onSubmit = async (data) => {
    try {
      console.log("google response for login", data);
      await mutation.mutate({ data });
    } catch (error) {
      console.log("error");
    }
  };
  const onGoogleSuccess = async (response) => {
    const data = {
      credential: response.credential,
    };
    await googleMutation.mutate(data);
  };
  const onGoogleFailure = () => {
    console.log("error occured in google");
  };

  if (isLoggedIn) {
    console.log(from);
    return <Navigate to={from} replace={true} state={location.state} />;
  }

  return (
    <>
      <div className="dashboard-main-colors min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src={Logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          {mutation.isLoading && <div>API Processing</div>}
          {mutation.isSuccess && (
            <div className="text-green-500">API Success</div>
          )}
          {mutation.isError && (
            <div className="text-red-500">
              {mutation.error.message === "data and hash arguments required"
                ? "Your email is registered with google login.Please use google login or reset your password for this email"
                : mutation.error.message}
            </div>
          )}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  className="input"
                  placeholder="Email address"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Enter a valid email",
                    },
                  })}
                />
              </div>
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="input"
                    placeholder="Password"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      minLength: { value: 8, message: "Minimum length is 8" },
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    {showPassword ? (
                      <div onClick={showPasswordHandler} className="z-20">
                        <AiFillEyeInvisible size={"1.5rem"} />
                      </div>
                    ) : (
                      <div onClick={showPasswordHandler} className="z-20">
                        <AiFillEye size={"1.5rem"} />
                      </div>
                    )}
                  </div>{" "}
                </div>
              </div>
              {errors.password && (
                <span className="text-red-500">{errors.password.message}</span>
              )}
            </div>

            <div className="flex items-center justify-end">
              <div className="text-sm">
                <Link
                  to="/forgot"
                  className="font-medium text-cyan-700 hover:text-cyan-500"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <SignUpButton type="submit">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Sign in
              </SignUpButton>
            </div>

            <div className="flex justify-center">
              <GoogleLogin
                type="standard"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                buttonText="Sign in with Google"
                onSuccess={onGoogleSuccess}
                onError={onGoogleFailure}
                cookiePolicy={"single_host_origin"}
              />
            </div>
            <div className="flex items-center justify-center">
              <div className="text-sm">
                Dont have an account?{" "}
                <Link
                  to="/register"
                  className="font-medium text-cyan-700 hover:text-cyan-500"
                >
                  Register Here
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
