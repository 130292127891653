import "@reach/dialog/styles.css";
import { Button } from "components/Button";
import useChangePassword from "components/hooks/useChangePassword";
import useEditProfileDetails from "components/hooks/useEditProfileDetails";
import Loading from "components/Loading";
import ChangePassword from "components/profile/ChangePassword";
import EditProfileDetails from "components/profile/EditProfileDetails";
import { getUser } from "queries/userQueries";
import React from "react";
import { useQuery } from "react-query";

export default function Profile() {
  const {
    showDialog,
    openEditDetails,
    closeEditDetails,
    onSubmit,
    methods: editMethods,
  } = useEditProfileDetails();

  const {
    showPasswordDialog,
    openPasswordDetails,
    closePasswordDetails,
    onPasswordSubmit,
    methods,
  } = useChangePassword();

  const { isLoading, isError, data } = useQuery("user", getUser);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div>Oops! Something went wrong!!!</div>;
  }
  return (
    <div className="flex flex-col justify-center">
      <h1 className="h1 text-center mb-12">My Profile</h1>
      <div className="flex justify-center items-center flex-col sm:flex-row">
        <div className="rounded-full h-24 w-24 bg-[#0A2B42] text-[#F0FEFC] flex justify-center items-center mr-6 mb-4">
          <p>UI</p>
        </div>
        <div>
          <div className="mb-4">
            Name: {data.firstName} {data.lastName}
          </div>
          <div className="mb-4">Email:{data.email}</div>
          <div className="mb-4">Phone: {data.mobileNumber || "-"}</div>
        </div>
      </div>{" "}
      <div className="flex justify-center mt-6">
        <Button onClick={openEditDetails}>Edit Details</Button>
        <Button onClick={openPasswordDetails}>Change Password</Button>
      </div>
      <EditProfileDetails
        showDialog={showDialog}
        closeEditDetails={closeEditDetails}
        onSubmit={onSubmit}
        data={data}
        methods={editMethods}
      />
      <ChangePassword
        showPasswordDialog={showPasswordDialog}
        closePasswordDetails={closePasswordDetails}
        onPasswordSubmit={onPasswordSubmit}
        methods={methods}
      />
    </div>
  );
}
