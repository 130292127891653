import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";
import Avatar from "assets/avatar.png";
const TestimonialCard = () => {
  return (
    <div className="testimonial-card-colors py-12 mb-12 px-6 sm:px-12 flex w-90 sm:w-2/3 mx-auto mt-20 rounded-3xl lg:w-1/2 flex flex-col lg:flex-row">
      <div className="flex flex-col md:flex-row">
        <p className="text-8xl">““</p>
        <div className="flex flex-col md:mt-5 ml-4">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Voluptatibus illum iure dignissimos
          </p>
          <div className="flex gap-2 items-center mt-4">
            <div className="flex gap-1">
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
            </div>
            <p>5.0</p>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-col items-center mt-5 ml-4">
        <img src={Avatar} className="w-16 " />
        <p className="text-center ml-4 lg:ml-0">Ramesh Arvind</p>
      </div>
    </div>
  );
};

export default function Testimonial() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="testimonial-colors max-w-[1920px] mx-auto py-20 my-20 -skew-y-3">
      <div className="skew-y-3 lg:my-12 px-12">
        <h2 className="h2 text-center"> Testimonials</h2>
        {/* Testimonial carousel  */}

        <Slider {...settings}>
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
        </Slider>

        <div className="testimonial-carousel relative">
          <div className="carousel-buttons">
            <div className="left-button absolute w-6 h-6 top-0 right-0"></div>
            <div className="right-button absolute w-6 h-6 top-0 right-0"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
