import Logo from "../../assets/Logo.jpeg";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { authSignUp } from "queries/authQueries";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useState } from "react";
import { saveToken, saveUser } from "helpers/storageHelper";
import useAuthStore from "stores/AuthStore";
import { errorToast } from "helpers/Toast";
import { SignUpButton } from "components/Button";
import useUserStore from "stores/UserStore";

export default function Register() {
  const queryClient = useQueryClient();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const role = useUserStore((state) => state.role);
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    location.state?.from?.pathname || role === "user"
      ? "/user/orders"
      : "/dashboard";
  const [showPassword, setShowPassword] = useState(false);
  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation(authSignUp, {
    onSuccess: (data) => {
      console.log(data);
      console.log("successfully Logged In");
      saveToken(data.accessToken, data.refreshToken);
      saveUser(data.user);
      navigate(from, { replace: true, state: location.state });
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await mutation.mutate({ data });
    } catch (error) {
      console.log("error");
    }
  };
  if (isLoggedIn) {
    console.log(from);
    return <Navigate to={from} replace={true} />;
  }
  return (
    <>
      <div className="dashboard-main-colors min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src={Logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Register your account
            </h2>
          </div>
          {mutation.isLoading && <div>API Processing</div>}
          {mutation.isError && <div>{mutation.error.message}</div>}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="firstName" className="sr-only">
                  First Name
                </label>
                <input
                  className="input"
                  placeholder="First Name"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              {errors.firstName && (
                <span className="text-red-500">{errors.firstName.message}</span>
              )}
              <div>
                <label htmlFor="password" className="sr-only">
                  Last Name
                </label>
                <input
                  className="input"
                  placeholder="Last Name"
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              {errors.lastName && (
                <span className="text-red-500">{errors.lastName.message}</span>
              )}

              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  className="input"
                  placeholder="Email address"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Enter a valid email",
                    },
                  })}
                />
              </div>
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="input"
                    placeholder="Password"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      minLength: { value: 8, message: "Minimum length is 8" },
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    {showPassword ? (
                      <div onClick={showPasswordHandler} className="z-20">
                        <AiFillEyeInvisible size={"1.5rem"} />
                      </div>
                    ) : (
                      <div onClick={showPasswordHandler} className="z-20">
                        <AiFillEye size={"1.5rem"} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {errors.password && (
                <span className="text-red-500">{errors.password.message}</span>
              )}
            </div>

            <div>
              <SignUpButton type="submit">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Register
              </SignUpButton>
            </div>
            <div className="flex items-center justify-center">
              <div className="text-sm">
                Already have an account?{" "}
                <Link
                  to="/login"
                  className="font-medium text-cyan-700 hover:text-cyan-500"
                >
                  Login Here
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
