import { Button } from "components/Button";
import { Input, Textarea } from "components/Input";
import { errorToast } from "helpers/Toast";
import {
  addBuyYourselfToOrder,
  createBuyYourself,
  orderPut,
} from "queries/orderQueries";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import "tippy.js/dist/tippy.css";
import { merchantDetails } from "utils/data";

const BuyYourselfAddItemInputs = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const { orderId } = useParams();
  // const location = useLocation();
  const navigate = useNavigate();
  // console.log("location.state");
  // console.log(location.state);
  const queryClient = useQueryClient();

  // Draft mentioned in query keys is status which is sent as url parameter

  const watchMerchant = watch("merchant");

  // const resetHandler = () => {
  //   reset();
  // };

  const mutationSuccessHandler = (data) => {
    console.log(data);
    reset();

    queryClient.invalidateQueries("orders");
    queryClient.invalidateQueries("allOrders");

    navigate(`/user/buy-yourself/${data._id}`);
    // setAddItem(false);
  };

  const createBuyYourselfOrderMutation = useMutation(createBuyYourself, {
    onSuccess: (data) => {
      mutationSuccessHandler(data);
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });

  const addBuyYourselfToOrdermutation = useMutation(addBuyYourselfToOrder, {
    onSuccess: (data) => {
      mutationSuccessHandler(data);
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });

  const convertToWeBuyMutation = useMutation(orderPut, {
    onSuccess: (data) => {
      reset();

      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("allOrders");

      navigate(`/user/we-buy-4-u/${data._id}`);
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });

  const isNewOrder = orderId === null || orderId === undefined ? true : false;
  // let existingOrderData = data && data.filter((item) => !item.isMedicine);
  const onSubmitHandler = (formData) => {
    isNewOrder
      ? (formData["orderType"] = "buyYourself")
      : (formData["orderId"] = orderId);
    formData["itemType"] = "default";

    isNewOrder
      ? createBuyYourselfOrderMutation.mutate(formData)
      : addBuyYourselfToOrdermutation.mutate({
          data: { ...formData },
          query: "add-new-buy-yourself",
        });
  };

  const onSubmit = async (formData) => {
    console.log("formData");
    console.log(formData);
    // tertiary function to facilitate push and put request based on condition
    formData = { ...formData };
    onSubmitHandler(formData);
  };

  const convertToWeBuyButtonHandler = () => {
    reset();
    orderId === null || orderId === undefined
      ? navigate("/user/we-buy-4-u")
      : convertToWeBuyMutation.mutate({
          data: { orderId, orderType: "weBuy" },
          query: "convert-to-we-buy",
        });
  };

  return (
    <div className="container mx-auto">
      <h3 className="h3 mb-4 text-center">Buy Yourself</h3>
      <div className="flex flex-col-reverse lg:flex-row justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap lg:min-w-[800px]">
            <div className="flex flex-col mr-6 mb-2 ">
              <div>Select your Online Store</div>
              <select
                {...register("merchant", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                className="px-2 lg:px-4 border-2 bg-blue-100 rounded-md py-2 min-w-[250px] lg:w-[350px] "
              >
                <option value="">--- Select Merchant ---</option>
                {merchantDetails.map((item) => (
                  <option value={item.name} key={item.id}>
                    {item.name}
                  </option>
                ))}
                <option value="others">Others</option>
              </select>

              {errors.merchant && (
                <span className="text-red-500">{errors.merchant.message}</span>
              )}
              {merchantDetails.find((item) => item.name === watchMerchant) && (
                <p>
                  <a
                    href={
                      merchantDetails.find(
                        (item) => item.name === watchMerchant
                      ).url
                    }
                    target="blank"
                    className="text-cyan-500 underline"
                  >
                    Click here
                  </a>{" "}
                  to visit <span className="capitalize">{watchMerchant}</span>
                </p>
              )}
            </div>
            <div className="">
              <Input
                title="Product URL/Link"
                inputName="productURL"
                placeholder="eg: https://www.amazon.in/robert-ludloom-7520"
                register={register}
                errors={errors}
                required
                maxLength={100}
              />
            </div>{" "}
          </div>
          {watchMerchant === "others" && (
            <div className="flex flex-wrap lg:min-w-[800px]">
              <div className="mr-6">
                <Input
                  title="Online Store Name"
                  inputName="otherMerchantName"
                  placeholder="eg: firstCry"
                  register={register}
                  errors={errors}
                  required
                  maxLength={100}
                />
              </div>
            </div>
          )}
          <div className="flex flex-wrap lg:min-w-[800px]">
            <div className="mr-6">
              <Input
                title="Product Name"
                inputName="name"
                placeholder="eg: Books"
                register={register}
                errors={errors}
                required
                maxLength={100}
              />
            </div>
            <div>
              <Input
                title="Product Quantity"
                inputName="quantity"
                placeholder="eg:50"
                register={register}
                errors={errors}
                inputType="number"
                required
                max={1000}
                min={1}
              />
            </div>
          </div>
          <div className="flex flex-wrap lg:min-w-[800px]">
            <div className="mr-6">
              <Textarea
                title="Description"
                inputName="description"
                register={register}
                errors={errors}
                maxLength={4000}
              />
            </div>

            <div className="flex flex-col">
              <Input
                title="Invoice Images"
                inputName="invoiceImages"
                inputType="file"
                register={register}
                errors={errors}
                multiple
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap lg:min-w-[800px]">
            <div className="mr-6">
              <Input
                title="Tracking Id"
                inputName="trackingId"
                register={register}
                errors={errors}
                required
              />
            </div>
          </div>
          <div className="flex">
            {/* <CancelButton
          className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
          onClick={() => {
            reset();
            setSelectedItemType("");
            setAddItem(false);
          }}
        >
          cancel
        </CancelButton> */}

            <Button
              type="submit"
              disabled={
                createBuyYourselfOrderMutation.isLoading ||
                addBuyYourselfToOrdermutation.isLoading
              }
            >
              {createBuyYourselfOrderMutation.isLoading ||
              addBuyYourselfToOrdermutation.isLoading
                ? "Processing..."
                : "Add Item"}
            </Button>
          </div>
        </form>
        <div className="">
          <div className="bg-[#B7D9E2] text-black p-4 mb-6">
            <p className="mb-4">Avail mulitple benifits with our services </p>
            <Button onClick={convertToWeBuyButtonHandler}>
              Switch to We Buy 4 U
            </Button>
          </div>
          <div className="bg-[#B7D9E2] text-black p-4 mb-4">
            <h4 className="font-bold text-xl">Warehouse Address</h4>

            <span>
              <strong>Name: </strong>
            </span>
            <span>Sundar</span>
            <br />
            <span>
              <strong>Address Line 1 :</strong>
            </span>
            <span>#85,1st cross</span>
            <br />
            <span>
              <strong>Address Line 2 :</strong>
            </span>
            <span>Baglur Cross</span>
            <br />
            <span>
              <strong>City :</strong>
            </span>
            <span>Bengalore</span>
            <br />
            <span>
              <strong>State :</strong>
            </span>
            <span>Karnataka</span>
            <br />
            <span>
              <strong>Country :</strong>
            </span>
            <span>India</span>
            <br />
            <span>
              <strong>Phone :</strong>
            </span>
            <span>+9198745632251</span>
            <br />
            <span>
              * The above number is only for logistics and courier. No enquires
              will be entertained.
            </span>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default BuyYourselfAddItemInputs;
