import { checkAuth } from "hooks/checkAuth";
import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";

const fetchQuery = async (urlPath, requestOptions) => {
  const res = await fetch(`${urlPath}`, requestOptions);
  const resjson = await res.json();
  if (!res.ok) setErrorData(res, resjson);
  return resjson;
};

const putRequestOptions = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  return requestOptions;
};

export async function getUser() {
  await checkAuth();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };
  console.log("Get User Data for address modal");
  const res = await fetch(`${baseURL}/users`, requestOptions);
  const resjson = await res.json();
  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function adminGetUser(data) {
  await checkAuth();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };
  console.log("Get User Data for address modal");
  const res = await fetch(
    `${baseURL}/users/admin-users?userId=${data}`,
    requestOptions
  );
  const resjson = await res.json();
  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function putEditDetails(data) {
  await checkAuth();
  const urlPath = `${baseURL}/users`;
  const requestOptions = await putRequestOptions(data);
  const response = await fetchQuery(urlPath, requestOptions);
  return response;
}

export async function putChangePassword(data) {
  await checkAuth();
  const urlPath = `${baseURL}/users/change-password`;
  const requestOptions = await putRequestOptions(data);
  const response = await fetchQuery(urlPath, requestOptions);
  return response;
}
