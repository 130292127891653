import { Button } from "components/Button";
import Loading from "components/Loading";
import DeleteModal from "components/modals/DeleteModal";
import DraftOrderActionButtons from "components/orderItems/DraftOrderActionButtons";
import UserDeleteButton from "components/UserDeleteButton";
import { errorToast, successToast } from "helpers/Toast";
import {
  deleteItemInOrder,
  deleteOrder,
  getSingleOrder,
} from "queries/orderQueries";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "tippy.js/dist/tippy.css";

const BuyYourselfDraftPages = () => {
  const { orderId } = useParams();

  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useQuery(
    ["orders", orderId],
    () => getSingleOrder(orderId)
  );

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    console.log(error);
    return <div>Something went wrong!!!</div>;
  }
  if (data === null) {
    return navigate("/user/orders");
  }
  return data.orderStatus === "draft" && data.orderType === "buyYourself" ? (
    <div className="max-w-[90%] ml-4">
      <table className="block overflow-x-auto whitespace-nowrap mt-4">
        <thead>
          <tr>
            <th className="px-2 text-center">Sl No</th>
            <th className="px-2 text-center">Name</th>
            <th className="px-2 text-center">Merchant</th>
            <th className="px-2 text-center">URL/Link</th>
            <th className="px-2 text-center">Quantity</th>
            <th className="px-2 text-center">Description</th>
            <th className="px-2 text-center">Tracking Id</th>
            <th className="px-2 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.items?.map((item, index) => (
            <tr>
              <td className="px-2 text-center">{index + 1}</td>
              <td className="px-2 text-center">{item.name}</td>
              <td className="px-2 text-center">
                {item.merchant === "others"
                  ? item.otherMerchantName
                  : item.merchant}
              </td>
              <td className="px-2 text-center">{item.productURL}</td>
              <td className="px-2 text-center">{item.quantity}</td>
              <td className="px-2 text-center">{item.description}</td>
              <td className="px-2 text-center">{item.trackingId}</td>
              <td className="px-2 text-center">
                <UserDeleteButton order={data} itemId={item._id} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-6">
        <Button onClick={() => navigate(`/user/${data._id}/in-house/`)}>
          Add In House Products
        </Button>
      </div>
      <div className="flex flex-wrap justify-center mt-6">
        <DraftOrderActionButtons order={data} />
      </div>
    </div>
  ) : (
    <Navigate to="/user/buy-yourself" />
  );
};

export default BuyYourselfDraftPages;
