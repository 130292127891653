import React from "react";
import { BiSupport } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
export default function SupportButton() {
  return (
    <React.Fragment>
      <button className="bg-gray-700  rounded-full p-2 mb-8 mt-4 mr-4 ">
        <a
          href="tel:+919036099389"
          className="font-bold bg-gray-700  text-white text-8 h-5 w-5"
        >
          <BiSupport />
        </a>
      </button>
      <button className="bg-gray-700  rounded-full p-2 mb-8 mt-4 mr-4 ">
        <a
          href="mailto:sales.storesal@gmail.com"
          className="font-bold bg-gray-700  text-white text-8 h-5 w-5 "
        >
          <FiMail />
        </a>
      </button>
    </React.Fragment>
  );
}
