import Loading from "components/Loading";
import ReadyToShipActionButtons from "components/orderItems/ReadyToShipActionButtons";
import ReviewOrderActionButtons from "components/orderItems/ReviewOrderActionButtons";
import { getSingleOrder } from "queries/orderQueries";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import OrderActionsTable from "./OrderActionsTable";
import OrdersTable from "./OrdersTable";

const OrderActions = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useQuery(
    ["orders", orderId],
    () => getSingleOrder(orderId)
  );
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    console.log(error);
    return <div>Something went wrong!!!</div>;
  }
  if (data === null) {
    return navigate("/user/orders");
  }
  return (
    <section className="bg-[#F0FEFC]">
      <div className="container mx-auto">
        <h2 className="h2 text-center mb-6 ">Order Actions</h2>
        <div className="flex justify-center">
          <OrderActionsTable data={data} />
        </div>
        <div className="flex justify-center">
          {data.orderStatus === "review" && (
            <ReviewOrderActionButtons order={data} />
          )}
          {data.orderStatus === "readyToShip" && (
            <div className="flex flex-col">
              <div>
                <div>The weight of the package is : {data.weight} kg</div>
                <div>
                  The shipping cost to <i>{data.address.country}</i> is ₹
                  {data.totalShippingCost}{" "}
                  {data.isDiscount && (
                    <>
                      <p>
                        You have availed a discount of ₹{data.discountAmount}
                      </p>
                      <p>
                        The payable amount is: ₹
                        {data.totalShippingCost - data.discountAmount}
                      </p>
                    </>
                  )}
                </div>
              </div>
              <ReadyToShipActionButtons order={data} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default OrderActions;
