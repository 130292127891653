const uploadSingleImage = async (image) => {
  try {
    const data = new FormData();
    console.log("single Image upload");
    data.append("file", image[0]);
    data.append("upload_preset", "storesal");
    data.append("cloud_name", "dwhno7ig2");
    console.log("array data");

    console.log(data);
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dwhno7ig2/image/upload",
      {
        method: "POST",
        body: data,
      }
    );

    const resjson = await res.json();
    console.log(resjson);

    return resjson.url;
  } catch (err) {
    console.log(err);
  }
};

const uploader = async (arrayData) => {
  const userImageArray = [];
  console.log("arrayData");

  console.log(arrayData);
  try {
    for (let i = 0; i < arrayData.length; i++) {
      const data = new FormData();
      data.append("file", arrayData[i]);
      data.append("upload_preset", "storesal");
      data.append("cloud_name", "dwhno7ig2");
      console.log("array data");

      console.log(data);
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dwhno7ig2/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      const resjson = await res.json();
      console.log(resjson);
      userImageArray.push(resjson.url);
    }
  } catch (err) {
    console.log(err);
  }

  console.log("userImageArray");

  console.log(userImageArray);
  return userImageArray;
};

// const deleteImage = async();
export { uploader, uploadSingleImage };
