import AboutUs from "components/HomePage/AboutUs";
import ContactUs from "components/HomePage/ContactUs";
import HeroSection from "components/HomePage/HeroSection";
import Testimonial from "components/HomePage/Testimonial";
import WhyStoresal from "components/HomePage/WhyStoresal";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import YouTube from "react-youtube";

export default function Home() {
  const { pathname, hash, key } = useLocation();

  const [videoViewerOpen, setVideoViewerOpen] = useState(false);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <>
      {videoViewerOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white group">
            <YouTube videoId="PK7c9aOb8NQ" />

            <button
              className="bg-red-500 px-4 py-2 my-2 rounded-md"
              onClick={() => setVideoViewerOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {/* Hero Page */}
      {/* Hero Section Start */}

      <HeroSection />
      {/* Hero Section End */}
      <AboutUs />
      <WhyStoresal />
      <Testimonial />
      <ContactUs />
    </>
  );
}
