import Loading from "components/Loading";
import ItemDetails from "pages/ItemDetails";
import { getSingleOrderWithItemId } from "queries/orderQueries";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const ViewDetailsPage = () => {
  const { itemId } = useParams();
  console.log(itemId);
  const { data, isLoading, isError, error } = useQuery(
    ["item", itemId],
    () => getSingleOrderWithItemId(itemId),
    {
      onSuccess: (data) => {
        const filteredData = data.items.find((item) => item._id === itemId);
        data.filteredItem = filteredData;
      },
    }
  );

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    console.log(error);
    return <div>Something went wrong!!!</div>;
  }

  return (
    <section>
      <h2 className="h2 text-center">Item details</h2>

      <div className="flex gap-4">
        <h3 className="font-bold">Name:</h3>
        <p>{data.filteredItem.name}</p>
      </div>

      <ItemDetails
        product={data.filteredItem}
        orderId={data._id}
        isReviewedEmailSent={data.isReviewedEmailSent}
        orderStatus={data.orderStatus}
        isMedicine={data.isMedicine}
        orderType={data.orderType}
      />
    </section>
  );
};

export default ViewDetailsPage;
