import React, { Fragment, useState } from "react";
import AddNewItems from "components/AddNewItems";
import { Button } from "components/Button";
import { getAllOrders } from "queries/orderQueries";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import DraftOrderActionButtons from "components/orderItems/DraftOrderActionButtons";
import ReviewOrderActionButtons from "components/orderItems/ReviewOrderActionButtons";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import InnerTable from "./InnerTable";

const NameHeader = ({ product }) => {
  return (
    <div className="flex mr-4 ">
      <h4 className="font-bold mr-1">Name:</h4>
      <p>{product.name}</p>
    </div>
  );
};
const QuantityHeader = ({ product }) => {
  return (
    <>
      {product.itemType !== "medicine" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Quantity:</h4>
          <p>{product.quantity}</p>
        </div>
      )}
    </>
  );
};
const ProcurableQuantityHeader = ({ product }) => {
  return (
    <>
      {product.itemType !== "medicine" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Procurable Quantity:</h4>
          <p>{product.procurableQuantity || "-"}</p>
        </div>
      )}
    </>
  );
};
const PriceHeader = ({ product }) => {
  return (
    <div className="flex mr-4 ">
      <h4 className="font-bold mr-1">Price:</h4>
      <p>{product.price || "-"}</p>
    </div>
  );
};
const TotalPriceHeader = ({ product }) => {
  return (
    <div className="flex mr-4 ">
      <h4 className="font-bold mr-1">Total Price:</h4>
      <p>{product.totalPrice || "-"}</p>
    </div>
  );
};
const TentativeDateHeader = ({ product }) => {
  return (
    <div className="flex mr-4 ">
      <h4 className="font-bold mr-1">Tentative Date:</h4>
      <p>
        {(product.tentativeDate &&
          new Date(product.tentativeDate).toLocaleDateString()) ||
          "-"}
      </p>
    </div>
  );
};
const IsProcurableHeader = ({ product }) => {
  return (
    <>
      <div className="flex mr-4 ">
        <h4 className="font-bold mr-1">Is Procurable:</h4>
        <p>{product.isProcurable ? "Procurable" : "Can not be procured"}</p>
      </div>
    </>
  );
};
const MerchantHeader = ({ product }) => {
  return (
    <>
      {product.itemType === "buyYourself" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Merchant:</h4>
          <p>
            {product.merchant === "others"
              ? product.otherMerchantName
              : product.merchant}
          </p>
        </div>
      )}
    </>
  );
};

const ProductURLHeader = ({ product }) => {
  //const processedProductURL = product.productURL.includes("https://")

  return (
    <>
      {product.itemType === "buyYourself" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Product Link:</h4>
          <a
            href={product.productURL}
            target="blank"
            className="text-cyan-500 underline"
          >
            click here
          </a>
        </div>
      )}
    </>
  );
};
const TrackingIdHeader = ({ product }) => {
  return (
    <>
      {product.itemType === "buyYourself" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Tracking Id:</h4>
          <p>{product.trackingId}</p>
        </div>
      )}
    </>
  );
};
const Name = ({ product }) => {
  return (
    <div className="flex mr-4 ">
      <h4 className="font-bold mr-1">Name:</h4>
      <p>{product.name}</p>
    </div>
  );
};
const Quantity = ({ product }) => {
  return (
    <>
      {product.itemType !== "medicine" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Quantity:</h4>
          <p>{product.quantity}</p>
        </div>
      )}
    </>
  );
};
const ProcurableQuantity = ({ product }) => {
  return (
    <>
      {product.itemType !== "medicine" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Procurable Quantity:</h4>
          <p>{product.procurableQuantity || "-"}</p>
        </div>
      )}
    </>
  );
};
const Price = ({ product }) => {
  return (
    <div className="flex mr-4 ">
      <h4 className="font-bold mr-1">Price:</h4>
      <p>{product.price || "-"}</p>
    </div>
  );
};
const TotalPrice = ({ product }) => {
  return (
    <div className="flex mr-4 ">
      <h4 className="font-bold mr-1">Total Price:</h4>
      <p>{product.totalPrice || "-"}</p>
    </div>
  );
};
const TentativeDate = ({ product }) => {
  return (
    <div className="flex mr-4 ">
      <h4 className="font-bold mr-1">Tentative Date:</h4>
      <p>
        {(product.tentativeDate &&
          new Date(product.tentativeDate).toLocaleDateString()) ||
          "-"}
      </p>
    </div>
  );
};
const IsProcurable = ({ product }) => {
  return (
    <>
      <div className="flex mr-4 ">
        <h4 className="font-bold mr-1">Is Procurable:</h4>
        <p>{product.isProcurable ? "Procurable" : "Can not be procured"}</p>
      </div>
    </>
  );
};
const Merchant = ({ product }) => {
  return (
    <>
      {product.itemType === "buyYourself" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Merchant:</h4>
          <p>
            {product.merchant === "others"
              ? product.otherMerchantName
              : product.merchant}
          </p>
        </div>
      )}
    </>
  );
};

const ProductURL = ({ product }) => {
  //const processedProductURL = product.productURL.includes("https://")

  return (
    <>
      {product.itemType === "buyYourself" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Product Link:</h4>
          <a
            href={product.productURL}
            target="blank"
            className="text-cyan-500 underline"
          >
            click here
          </a>
        </div>
      )}
    </>
  );
};
const TrackingId = ({ product }) => {
  return (
    <>
      {product.itemType === "buyYourself" && (
        <div className="flex mr-4 ">
          <h4 className="font-bold mr-1">Tracking Id:</h4>
          <p>{product.trackingId}</p>
        </div>
      )}
    </>
  );
};

const OrderTableHeader = ({}) => {
  return (
    <thead className="">
      <tr className="">
        <th className="px-2"></th>
        <th className="px-2 text-center capitalize">Id</th>
        <th className="px-2 text-center capitalize">Services</th>
        <th className="px-2 text-center capitalize">Start Date</th>
        <th className="px-2 text-center capitalize">End Date</th>
        <th className="px-2 text-center capitalize">Total Value</th>
        <th className="px-2 text-center capitalize">Status</th>
      </tr>
    </thead>
  );
};

const OrderTableBody = ({
  editOrderNavigator,
  setInnerdetails,
  innerDetails,
  item,
  index,
}) => {
  return (
    <Fragment key={item._id}>
      <tr>
        <td className="px-2 text-center capitalize">
          {innerDetails === item._id ? (
            <AiFillCaretUp size={20} onClick={() => setInnerdetails(false)} />
          ) : (
            <AiFillCaretDown
              size={20}
              onClick={() => setInnerdetails(item._id)}
            />
          )}
        </td>

        <td className="px-2 text-center capitalize">
          {item.uniqueOrderId || index + 1}
        </td>
        <td className="px-2 text-center capitalize">
          {item.orderType || "- "}
        </td>
        <td className="px-2 text-center capitalize">
          {new Date(item.createdAt).toLocaleDateString("en-IN")}
        </td>
        <td className="px-2 text-center capitalize">
          {item.completedDate || "N/A"}
        </td>
        <td className="px-2 text-center capitalize">
          {item.totalValue || "N/A"}
        </td>
        <td className="px-2 text-center capitalize">{item.orderStatus}</td>

        <td className="px-2 text-center capitalize ">
          {item.orderStatus === "draft" && (
            // <DraftOrderActionButtons order={item} />
            <Button onClick={() => editOrderNavigator(item)}>Edit Order</Button>
          )}
          {item.orderStatus === "review" &&
            item.isReviewedEmailSent === true && (
              <Link to={`/user/actions/${item._id}`}>Approval awaited</Link>
            )}
          {item.orderStatus === "readyToShip" && (
            <Link to={`/user/actions/${item._id}`}>Approval awaited</Link>
          )}
        </td>
      </tr>
      {innerDetails === item._id && (
        <tr>
          <td colSpan="10">
            <InnerTable data={item} hideActions={true} />
          </td>
        </tr>
      )}
    </Fragment>
  );
};

const OrdersTable = ({ data, hideActions }) => {
  const [innerDetails, setInnerdetails] = useState(false);
  console.log("innerdetails ", innerDetails);
  const navigate = useNavigate();
  const editOrderNavigator = (data) => {
    if (data.orderType === "buyYourself") {
      navigate(`/user/buy-yourself/${data._id}`);
    }
    if (data.orderType === "weBuy") {
      navigate(`/user/we-buy-4-u/${data._id}`);
    }
    if (data.orderType === "medicine") {
      navigate(`/user/medicine/${data._id}`);
    }
    if (data.orderType === "customized") {
      navigate(`/user/customized-buy/${data._id}`);
    }
    if (data.orderType === "inhouse") {
      navigate(`/user/inhouse/${data._id}`);
    }
  };
  return (
    <table className="block overflow-x-auto whitespace-nowrap mt-4">
      <OrderTableHeader hideActions={hideActions} />
      <tbody>
        {data.length !== 0 ? (
          data.length > 1 ? (
            data.map((item, index) => (
              <OrderTableBody
                editOrderNavigator={editOrderNavigator}
                innerDetails={innerDetails}
                setInnerdetails={setInnerdetails}
                item={item}
                index={index}
                hideActions={hideActions}
              />
            ))
          ) : (
            <OrderTableBody
              editOrderNavigator={editOrderNavigator}
              innerDetails={innerDetails}
              setInnerdetails={setInnerdetails}
              item={data}
              index={0}
              hideActions={hideActions}
            />
          )
        ) : (
          <tr>
            <td colSpan={10} className=" px-2 md:text-center">
              No Orders History
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default OrdersTable;
