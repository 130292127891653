import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AppRoutes from "./routes";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ToastBox } from "helpers/Toast";
import { IoLogoWhatsapp } from "react-icons/io";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <div className="min-h-screen">
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId="840694255919-35f7u950nhvoofiboactqj7adhklu2hl.apps.googleusercontent.com">
            <AppRoutes />
            <ToastBox />
            <a
              href="https://wa.me/+919036099389"
              target="blank"
              className="fixed bottom-24 right-4"
            >
              <IoLogoWhatsapp size={52} color="green" />
            </a>
            <ReactQueryDevtools />
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </div>
    </>
  );
}

export default App;
