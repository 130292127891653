import { Button } from "components/Button";
import SupportButton from "components/SupportButton";
import { successToast } from "helpers/Toast";
import { userLogisticsPayment } from "queries/orderQueries";
import React from "react";
import { useMutation, useQueryClient } from "react-query";

const ReadyToShipActionButtons = ({ order }) => {
  const role = localStorage.getItem("userRole");
  const queryClient = useQueryClient();
  const userAcknowledgedMutation = useMutation(userLogisticsPayment, {
    onSuccess: (data) => {
      console.log(order._id);
      console.log(data);

      successToast(
        "Successfully completed Payment. The items will be Shipped soon"
      );
      queryClient.invalidateQueries("orders");
    },
    onError: () => {
      console.log(order._id);
    },
  });
  return (
    <div>
      {role === "user" ? (
        order.isLogisticsChargePaid ? (
          <div className="text-cyan-700 mt-2">Order will be shipped soon</div>
        ) : (
          <>
            <SupportButton />
            <Button
              className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
              onClick={() => userAcknowledgedMutation.mutate(order._id)}
            >
              Pay Shipping Cost
            </Button>
          </>
        )
      ) : null}
    </div>
  );
};

export default ReadyToShipActionButtons;
