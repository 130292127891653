import Logo from "../../assets/Logo.jpeg";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link, Redirect, useParams, useNavigate } from "react-router-dom";
import { resetPasswordByEmail } from "queries/authQueries";
import { saveToken, saveUser } from "helpers/storageHelper";
import { baseURL } from "utils/constants";
import { data } from "autoprefixer";
import { errorToast, successToast } from "helpers/Toast";
import { SignUpButton } from "components/Button";

export default function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const reset = useQuery(
    "reset",
    async () => {
      const res = await fetch(
        `${baseURL}/auth/reset?resetPasswordToken=${params.id}`
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      return res.json();
    },
    {
      onSuccess: (data) => {
        console.log(data);
        localStorage.setItem("email", data.email);
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const mutation = useMutation(resetPasswordByEmail, {
    onSuccess: (data) => {
      console.log(data);
      console.log("successfully Changed Password");
      successToast("Successfully Changed Password");
      navigate("/login");
    },
    onError: (error) => {
      errorToast(error.message);
    },
  });

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const dataNew = {
        email: localStorage.getItem("email"),
        resetPasswordToken: params.id,
        password: data.password,
      };
      console.log(data);
      await mutation.mutate({ data: dataNew });
    } catch (error) {
      console.log("error");
    }
  };

  if (reset.isLoading) {
    return <div>... Loading ...</div>;
  }
  if (reset.isError) {
    return <div>Access Denied To This page</div>;
  }

  return (
    <>
      <div className="dashboard-main-colors min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src={Logo} alt="Workflow" />
            <h2 className="mt-6 mb-6 text-center text-3xl font-extrabold text-gray-900">
              Enter your password
            </h2>
          </div>
          {mutation.isLoading && <div>API Processing</div>}
          {mutation.isSuccess && (
            <div className="text-green-500">API Success</div>
          )}
          {mutation.isError && (
            <div className="text-red-500">{mutation.error.message}</div>
          )}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type="password"
                  className="input"
                  placeholder="Enter your new password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    minLength: { value: 8, message: "Minimum length is 8" },
                  })}
                />
              </div>
              {errors.password && (
                <span className="text-red-500">{errors.password.message}</span>
              )}
            </div>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="confirm" className="sr-only">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="input"
                  placeholder="Enter your new password"
                  {...register("confirm", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },

                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                />
              </div>
              {errors.confirm && (
                <span className="text-red-500">{errors.confirm.message}</span>
              )}
            </div>
            <div>
              <SignUpButton type="submit">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Submit Password
              </SignUpButton>
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                Remember your password?{" "}
                <Link
                  to="/login"
                  className="font-medium text-cyan-700 hover:text-cyan-500"
                >
                  Login Here
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
