import { Button } from "components/Button";
import Loading from "components/Loading";
import DraftOrderActionButtons from "components/orderItems/DraftOrderActionButtons";
import UserDeleteButton from "components/UserDeleteButton";
import { getSingleOrder } from "queries/orderQueries";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import "tippy.js/dist/tippy.css";

const InHouseDraftPages = () => {
  const { orderId } = useParams();

  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useQuery(
    ["orders", orderId],
    () => getSingleOrder(orderId)
  );

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    console.log(error);
    return <div>Something went wrong!!!</div>;
  }
  if (data === null) {
    return navigate("/user/orders");
  }
  return (
    <div className=" container mx-auto">
      <h3 className="h3 mb-4 text-center">In House products</h3>

      <div className="flex justify-center">
        {data.orderStatus === "draft" && data.orderType === "inhouse" ? (
          <div className="max-w-[90%] ">
            <table className="block overflow-x-auto whitespace-nowrap mt-4">
              <thead>
                <tr>
                  <th className="px-2 text-center">Sl No</th>
                  <th className="px-2 text-center">Name</th>

                  <th className="px-2 text-center">Description</th>
                  <th className="px-2 text-center">Quantity</th>

                  <th className="px-2 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.items?.map((item, index) => (
                  <tr>
                    <td className="px-2 text-center">{index + 1}</td>
                    <td className="px-2 text-center">{item.name}</td>

                    <td className="px-2 text-center">{item.description}</td>
                    <td className="px-2 text-center">{item.quantity}</td>

                    <td className="px-2 text-center">
                      <UserDeleteButton order={data} itemId={item._id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-6">
              <Button onClick={() => navigate(`/user/${data._id}/in-house/`)}>
                Add In House Products
              </Button>
            </div>
            <div className="flex flex-wrap justify-center mt-6">
              <DraftOrderActionButtons order={data} />
            </div>
          </div>
        ) : (
          navigate("/user/orders")
        )}
      </div>
    </div>
  );
};

export default InHouseDraftPages;
