import { successToast } from "helpers/Toast";
import {
  adminAddReviewDetails,
  adminAddShippingCost,
  adminConfirmReviewed,
  adminMarkProcured,
  adminNotifyCustumerOfShippingCost,
  adminWeightandSize,
  getOrders,
  userAckonwledgedReviewed,
} from "queries/orderQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ToggleSwitch from "react-switch";
import ItemDetails from "pages/ItemDetails";
import Loading from "components/Loading";

export default function Procuring() {
  const role = localStorage.getItem("userRole");
  const queryClient = useQueryClient();
  const [itemId, setItemId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isPriceModalOpen, setPriceModalOpen] = useState(false);
  const [isConfirmReviewedOpen, setConfirmReviewedOpen] = useState(false);
  const [reviewData, setReveiwData] = useState();
  const [isDiscountAvailable, setDiscountAvailable] = useState(false);
  const [ordersWeightAndSize, setOrdersWeightAndSize] = useState();
  const [shippingCostValue, setShippingCostValue] = useState();
  const [paymentGatewayOpen, setPaymentGatewayOpen] = useState(false);
  const [shippingModalOpen, setShippingModalOpen] = useState(false);
  const [viewDetailsOpen, setViewDetailsOpen] = useState();

  let isReviewedVerifier = 0;
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = useForm();
  const { data, isLoading, isError, error } = useQuery(
    ["orders", "procuring"],
    () => getOrders("procuring ")
  );

  const mutation = useMutation(adminWeightandSize, {
    onSuccess: (data) => {
      console.log(data);
      reset();
      setOrderId("");
      setItemId("");
      setOrdersWeightAndSize("");
      setPriceModalOpen(false);
      successToast("Successfully updated weight and size");
      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("orders");
    },
  });

  const shippingMutation = useMutation(adminAddShippingCost, {
    onSuccess: (data) => {
      console.log(data);
      reset();
      setOrderId("");
      setItemId("");
      setDiscountAvailable(false);
      setShippingCostValue("");
      setShippingModalOpen(false);
      successToast("Successfully updated shipping cost");

      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("orders");
    },
  });

  const readyToShipMutation = useMutation(adminNotifyCustumerOfShippingCost, {
    onSuccess: (data) => {
      successToast("The order is moved to ready to be shipped");
      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("orders");
    },
  });

  const confirmMutation = useMutation(adminMarkProcured, {
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("orders");
    },
  });

  const userAcknowledgedMutation = useMutation(userAckonwledgedReviewed, {
    onSuccess: (data) => {
      console.log(orderId);
      console.log(data);
      setPaymentGatewayOpen(false);
      successToast(
        "Successfully completed Payment. The items will be procured soon"
      );
      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("orders");
    },
    onError: () => {
      console.log(orderId);
    },
  });

  const onCancelHandler = () => {
    reset();
    setOrderId("");
    setItemId("");
    setOrdersWeightAndSize("");
    setShippingCostValue("");
    setShippingModalOpen(false);
    setPriceModalOpen(false);
  };

  const detailsHandler = (orders) => {
    setOrderId(orders._id);
    setOrdersWeightAndSize({
      weight: orders.weight,
      height: orders.height,
      length: orders.length,
      width: orders.width,
      denominator: orders.denominator,
    });
    setPriceModalOpen(true);
  };
  const detailsShippingWeightHandler = (orders) => {
    setOrderId(orders._id);
    setDiscountAvailable(orders.isDiscount || false);
    setShippingCostValue({
      shippingCost: orders.shippingCost,
      packagingCost: orders.packagingCost,
      discountAmount: orders.discountAmount,
    });
    setShippingModalOpen(true);
  };
  const onSubmit = (formData) => {
    console.log(formData);
    console.log("OrderId and ItemID");
    console.log(formData);
    console.log(orderId);
    const newData = { ...formData, orderId };
    console.log(newData);
    mutation.mutate(newData);
  };

  const onShippingCostSubmit = (formData) => {
    console.log(formData);
    const totalShippingCost =
      parseInt(formData.shippingCost) +
      parseInt(formData.packagingCost) +
      parseInt(formData.packagingCost) * 0.18;
    const newData = {
      ...formData,
      orderId,
      isDiscount: isDiscountAvailable,
      totalShippingCost,
    };
    console.log(newData);
    shippingMutation.mutate(newData);
  };

  const verifier = (status) => {
    // console.log(price);
    if (status === "procured") {
      isReviewedVerifier += 1;
    }
    // console.log(isReviewedVerifier);
  };
  const resetVerifier = () => {
    isReviewedVerifier = 0;
  };
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    console.log(error);
    return <div>Something went wrong!!!</div>;
  }

  return (
    <>
      {paymentGatewayOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Payment Gateway</h1>
            <div>
              <div className="mb-6">
                The total price to procure the items are 1500 USD
              </div>
              <button
                className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                onClick={() => setPaymentGatewayOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-700 font-bold py-2 text-white px-6 rounded-md m-auto"
                onClick={() => userAcknowledgedMutation.mutate(orderId)}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      )}
      {isPriceModalOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Add Size Details</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col ">
                <label htmlFor="weight" className="text-left">
                  Weight(kg)
                </label>
                <input
                  className="input"
                  type="number"
                  step="any"
                  placeholder="Weight "
                  defaultValue={ordersWeightAndSize?.weight || ""}
                  {...register("weight", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 1,
                      message: "The value cannot be lesser than 1",
                    },
                  })}
                />
                {errors.weight && (
                  <span className="text-red-500">{errors.weight.message}</span>
                )}
              </div>

              <div className="flex flex-col ">
                <label htmlFor="height" className="text-left">
                  Height(cm)
                </label>
                <input
                  className="input"
                  placeholder="height"
                  defaultValue={ordersWeightAndSize?.height || ""}
                  {...register("height", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 1,
                      message: "The value cannot be lesser than 1",
                    },
                  })}
                />
                {errors.height && (
                  <span className="text-red-500">{errors.height.message}</span>
                )}
              </div>
              <div className="flex flex-col ">
                <label htmlFor="width" className="text-left">
                  Width(cm)
                </label>
                <input
                  className="input"
                  type="number"
                  defaultValue={ordersWeightAndSize?.width || ""}
                  placeholder="Width "
                  {...register("width", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 1,
                      message: "The value cannot be lesser than 1",
                    },
                  })}
                />
                {errors.width && (
                  <span className="text-red-500">{errors.width.message}</span>
                )}
              </div>
              <div className="flex flex-col">
                <label htmlFor="length" className="text-left">
                  Length(cm)
                </label>
                <input
                  className="input"
                  placeholder="Length"
                  defaultValue={ordersWeightAndSize?.length || ""}
                  {...register("length", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 1,
                      message: "The value cannot be lesser than 1",
                    },
                  })}
                  type="number"
                />
                {errors.length && (
                  <span className="text-red-500">{errors.length.message}</span>
                )}
              </div>
              <div className="flex flex-col">
                <label htmlFor="denominator" className="text-left">
                  Denominator
                </label>
                <input
                  className="input"
                  placeholder="Denominator"
                  defaultValue={ordersWeightAndSize?.denominator || ""}
                  {...register("denominator", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 1,
                      message: "The value cannot be lesser than 1",
                    },
                  })}
                  type="number"
                />
                {errors.denominator && (
                  <span className="text-red-500">
                    {errors.denominator.message}
                  </span>
                )}
              </div>
              <div>
                <button
                  className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
                <button className="bg-blue-500 px-4 py-2 rounded-md">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {shippingModalOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Add Cost Details</h1>
            <form onSubmit={handleSubmit(onShippingCostSubmit)}>
              <div className="flex flex-col ">
                <label htmlFor="shippingCost" className="text-left">
                  Shipping Cost
                </label>
                <input
                  className="input"
                  type="number"
                  step="any"
                  defaultValue={shippingCostValue?.shippingCost || ""}
                  placeholder="Shipping Cost "
                  {...register("shippingCost", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 1,
                      message: "The value cannot be lesser than 1",
                    },
                  })}
                />
                {errors.shippingCost && (
                  <span className="text-red-500">
                    {errors.shippingCost.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col ">
                <label htmlFor="packagingCost" className="text-left">
                  Packaging Cost
                </label>
                <input
                  className="input"
                  type="number"
                  step="any"
                  defaultValue={shippingCostValue?.packagingCost || ""}
                  placeholder="Packaging Cost "
                  {...register("packagingCost", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 1,
                      message: "The value cannot be lesser than 1",
                    },
                  })}
                />
                {errors.packagingCost && (
                  <span className="text-red-500">
                    {errors.packagingCost.message}
                  </span>
                )}
              </div>
              {/* <div className="flex flex-col ">
                <label htmlFor="totalshippingCost" className="text-left">
                  Total Shipping Cost
                </label>
                <input
                  className="input"
                  type="number"
                  step="any"
                  defaultValue={shippingCostValue?.totalshippingCost || ""}
                  placeholder="Total Shipping Cost "
                  {...register("totalshippingCost", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 1,
                      message: "The value cannot be lesser than 1",
                    },
                  })}
                />
                {errors.totalshippingCost && (
                  <span className="text-red-500">
                    {errors.totalshippingCost.message}
                  </span>
                )}
              </div> */}

              <div className="flex flex-row my-2">
                <label htmlFor="isdiscount" className="text-left">
                  Is Discount Available?
                </label>
                <ToggleSwitch
                  className="ml-6"
                  checked={isDiscountAvailable}
                  onChange={() => setDiscountAvailable(!isDiscountAvailable)}
                />
              </div>
              {isDiscountAvailable && (
                <div className="flex flex-col ">
                  <label htmlFor="discountAmount" className="text-left">
                    Discount Amount
                  </label>
                  <input
                    className="input"
                    type="number"
                    step="any"
                    defaultValue={shippingCostValue?.discountAmount || ""}
                    placeholder="Discount Amount"
                    {...register("discountAmount", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      min: {
                        value: 1,
                        message: "The value cannot be lesser than 1",
                      },
                    })}
                  />
                  {errors.discountAmount && (
                    <span className="text-red-500">
                      {errors.discountAmount.message}
                    </span>
                  )}
                </div>
              )}
              <div>
                <button
                  className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
                <button className="bg-blue-500 px-4 py-2 rounded-md">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* TODO: Create this flow after asking if it is required for the usecase and what details should be displayed in this */}
      {/* {isConfirmReviewedOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Confirm Review Details</h1>
            <div>{reviewData.map((data) => (

<div>

            ))}</div>

            <div>
              <button
                className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                onClick={onCancelHandler}
              >
                Cancel
              </button>
              <button className="bg-blue-500 px-4 py-2 rounded-md">
                Submit
              </button>
            </div>
          </div>
        </div>
      )} */}
      <div>
        {/* delete Modal */}

        {data.length !== 0 ? (
          <div>
            {data.map((orders, index) => (
              <div className="mt-6" key={orders._id}>
                <div className="hidden">{(isReviewedVerifier = 0)}</div>

                <div className="flex">
                  <h3 className="font-medium mr-4">Order ID:</h3>
                  <p>{orders.uniqueOrderId}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium mr-4">User Email:</h3>
                  <p>{orders.userEmail}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium mr-4">Order Type:</h3>
                  <p className="capitalize">{orders.orderType}</p>
                </div>
                <table className="table-auto border border-rounded-xl">
                  <thead className="bg-gray-300">
                    <tr>
                      <th className="px-4">
                        {orders.isMedicine ? "Name" : "Product Name"}
                      </th>

                      {role !== "user" && <th className="px-4">Description</th>}
                      <th className="px-4"> Quantity</th>
                      <th className="px-4">Price</th>
                      <th className="px-4">Total Price</th>
                      <th className="px-4">Tentative Date</th>
                      <th className="px-4">Details</th>
                      <th className="px-4">Status</th>

                      <th className="px-4"></th>
                      <th className="px-4"></th>
                    </tr>
                  </thead>
                  <tbody key={orders._id} className="mb-8 border">
                    {orders.items
                      .filter(
                        (item) =>
                          orders.orderType === "buyYourself" ||
                          (item.isProcurable && item.userAcknowledge)
                      )
                      .map((product) => (
                        <>
                          <tr key={product._id}>
                            <td className="text-center px-6">{product.name}</td>

                            {role !== "user" && (
                              <td className="text-center px-6 truncate  max-w-[300px]">
                                {product.description}
                              </td>
                            )}
                            <td className="text-center px-6">
                              {product.itemType === "buyYourself"
                                ? product.quantity
                                : product.procurableQuantity || "-"}
                            </td>
                            <td className="text-center px-6">
                              {product.price || "-"}
                            </td>
                            <td className="text-center px-6">
                              {product.totalPrice || "-"}
                            </td>
                            <td className="text-center px-6">
                              {(product.tentativeDate &&
                                new Date(
                                  product.tentativeDate
                                ).toLocaleDateString()) ||
                                "-"}
                            </td>
                            <td className="text-center px-6">
                              <div className="flex items-center">
                                {viewDetailsOpen === product._id ? (
                                  <button
                                    // to="/"
                                    className="mr-4"
                                    onClick={() => setViewDetailsOpen()}
                                  >
                                    Hide details
                                  </button>
                                ) : (
                                  <button
                                    // to="/"
                                    className="mr-4"
                                    onClick={() =>
                                      setViewDetailsOpen(product._id)
                                    }
                                  >
                                    View details
                                  </button>
                                )}
                              </div>
                            </td>
                            <td className="text-center px-6">
                              {product.status === "confirmation" ||
                              product.status === "draft" ||
                              product.status === "progress"
                                ? "In Progress"
                                : product.status}
                            </td>

                            <td className="text-center px-6">
                              <div className="flex items-center">
                                {/* <Link to="/" className="mr-4">
                              View details
                            </Link> */}
                                {localStorage.getItem("userRole") ===
                                "user" ? null : (
                                  <>
                                    {/* <button
                                  className="bg-blue-700 px-3 py-2 text-white rounded-lg"
                                  onClick={() => {
                                    const newData = {
                                      orderId: orders._id,
                                      itemId: product._id,
                                      isProcured: !product.isProcured,
                                    };
                                    confirmMutation.mutate(newData);
                                  }}
                                >
                                  {product.isProcured
                                    ? "Procured"
                                    : "Mark as Procured"}
                                </button> */}
                                    <ToggleSwitch
                                      className=" mr-6"
                                      checked={product.isProcured}
                                      onChange={() => {
                                        const newData = {
                                          orderId: orders._id,
                                          itemId: product._id,
                                          isProcured: !product.isProcured,
                                        };
                                        confirmMutation.mutate(newData);
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`text-xs text-center p-2 rounded-full m-2 +
                                  ${
                                    product.itemType === "cart"
                                      ? "bg-orange-100"
                                      : "bg-teal-100"
                                  }`}
                              >
                                {product.itemType === "cart"
                                  ? "In House"
                                  : orders.orderType}
                              </div>
                            </td>
                            {verifier(product.status)}
                          </tr>
                          {viewDetailsOpen === product._id && (
                            <ItemDetails
                              product={product}
                              orderId={orders._id}
                              isReviewedEmailSent={orders.isReviewedEmailSent}
                              orderStatus={orders.orderStatus}
                              isMedicine={orders.isMedicine}
                              isPremiumAvailed={orders.isPremiumAvailed}
                              orderType={orders.orderType}
                            />
                          )}
                        </>
                      ))}
                  </tbody>
                </table>

                {role !== "user" ? (
                  isReviewedVerifier ===
                  orders.items.filter(
                    (item) =>
                      orders.orderType === "buyYourself" ||
                      (item.isProcurable && item.userAcknowledge)
                  ).length ? (
                    <>
                      {orders.denominator && (
                        <>
                          <div>
                            The weight of the package is : {orders.weight} kg
                          </div>
                          <div>
                            The volumetric weight for {orders.width}x
                            {orders.height}x{orders.length}
                            (w<i>x</i>h<i>x</i>l in cm) :
                            {(orders.width * orders.height * orders.length) /
                              orders.denominator}{" "}
                            kg
                          </div>
                          {orders.totalShippingCost && (
                            <div>
                              The total shipping cost to{" "}
                              <i>{orders.address.country}</i> is{" "}
                              {orders.totalShippingCost}
                            </div>
                          )}
                          <button
                            className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mr-6"
                            onClick={() => {
                              detailsShippingWeightHandler(orders);
                              // detailsHandler(orders._id);
                            }}
                          >
                            {orders.totalShippingCost === undefined
                              ? "Add Shipping Cost"
                              : "Edit Shipping Cost"}
                          </button>
                        </>
                      )}

                      <button
                        className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mr-6"
                        onClick={() => {
                          detailsHandler(orders);
                        }}
                      >
                        {orders.denominator === undefined
                          ? "Add Weight and Size"
                          : "Edit Weight and Size"}
                      </button>
                      {orders.totalShippingCost && (
                        <button
                          className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mr-6"
                          onClick={() => {
                            readyToShipMutation.mutate(orders._id);
                            // detailsHandler(orders._id);
                          }}
                        >
                          Notify Customer
                        </button>
                      )}
                    </>
                  ) : null
                ) : null}
              </div>
            ))}

            {/* <div className="mt-6">
              <button
                className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-8"
                onClick={() => deleteOrderHandler(orders._id)}
              >
                Delete Order
              </button>
              <button
                className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8"
                onClick={() => submitOrderMutation.mutate(orders._id)}
              >
                Submit Order
              </button>
            </div> */}
          </div>
        ) : (
          <div>No orders in Procuring</div>
        )}
      </div>
    </>
  );
}
