import { Button } from "components/Button";
import DeleteModal from "components/modals/DeleteModal";
import DraftOrderActionButtons from "components/orderItems/DraftOrderActionButtons";
import { errorToast, successToast } from "helpers/Toast";
import {
  deleteItemInOrder,
  deleteOrder,
  getSingleOrder,
} from "queries/orderQueries";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "tippy.js/dist/tippy.css";

const UserDeleteButton = ({ order, itemId }) => {
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleteOrderModalOpen, setDeleteOrderModalOpen] = useState(false);

  const deleteMutationSuccessHandler = (data) => {
    console.log(data);
    queryClient.invalidateQueries("allOrders");
    queryClient.invalidateQueries("orders");
    // setItemId("");
    // setOrderId("");
    // setSelectedItemType("");
    setDeleteModalOpen(false);
    setDeleteOrderModalOpen(false);
  };
  const deleteOrderMutation = useMutation(deleteOrder, {
    onSuccess: (data) => {
      successToast("Successfully Deleted Order");
      deleteMutationSuccessHandler(data);
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });

  const deleteItemMutation = useMutation(deleteItemInOrder, {
    onSuccess: (data) => {
      successToast("Successfully Deleted Item");
      deleteMutationSuccessHandler(data);
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });

  return (
    <>
      {order.items.length <= 1 ? (
        <MdDelete
          className="mx-auto"
          onClick={() => setDeleteOrderModalOpen(true)}
        />
      ) : (
        <MdDelete
          className="mx-auto"
          onClick={() => setDeleteModalOpen(true)}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          title="Are you sure you want to delete this item?"
          onCancel={() => setDeleteModalOpen(false)}
          onDelete={() =>
            deleteItemMutation.mutate({
              itemId,
              orderId: order._id,
            })
          }
        />
      )}
      {isDeleteOrderModalOpen && (
        <DeleteModal
          title="Are you sure you want to delete this order?"
          onCancel={() => setDeleteOrderModalOpen(false)}
          onDelete={() =>
            deleteOrderMutation.mutate({
              itemId,
              orderId: order._id,
            })
          }
        />
      )}
    </>
  );
};

export default UserDeleteButton;
