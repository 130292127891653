import { Input, Textarea } from "components/Input";
import {
  createCategory,
  deleteCategory,
  editCategory,
  getCategories,
} from "queries/categoryQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DeleteModal from "components/modals/DeleteModal";
import Loading from "components/Loading";

export default function AdminCategories() {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, error } = useQuery(
    "categories",
    getCategories
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [category, setCategory] = useState("");

  const onSubmit = async (formData) => {
    createCategoryMutation.mutate(formData);
  };
  const onEditSubmit = async (formData) => {
    editCategoryMutation.mutate({ ...formData, categoryId: category._id });
  };

  const createCategoryMutation = useMutation(createCategory, {
    onSuccess: (data) => {
      setCategory("");
      setAddCategoryModalOpen(false);
      queryClient.invalidateQueries("categories");
    },
  });
  const editCategoryMutation = useMutation(editCategory, {
    onSuccess: (data) => {
      setCategory("");
      setEditCategoryModalOpen(false);
      queryClient.invalidateQueries("categories");
    },
  });
  const deleteCategoryMutation = useMutation(deleteCategory, {
    onSuccess: (data) => {
      setDeleteModalOpen(false);
      queryClient.invalidateQueries("categories");
    },
  });

  const onCategoryModalCancel = () => {
    setCategory();
    setDeleteModalOpen(false);
  };

  const onCategoryModalDelete = () => {
    deleteCategoryMutation.mutate(category._id);
  };
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    console.log(error);
    return <div>Something went wrong!!!</div>;
  }

  return (
    <>
      {isDeleteModalOpen && (
        <>
          {console.log("deletemodal is open")}
          <DeleteModal
            title="Are you sure you want to delete this category? All products associated with this category will be deleted?"
            onCancel={onCategoryModalCancel}
            onDelete={onCategoryModalDelete}
          />
        </>
      )}
      {editCategoryModalOpen && (
        <>
          <div className="fixed w-screen h-screen top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
            <div className="bg-white p-6">
              <h1 className="mb-8 ">Edit Category</h1>
              <form onSubmit={handleSubmit(onEditSubmit)}>
                <div className="">
                  <Input
                    title="Category Name"
                    inputName="name"
                    register={register}
                    defaultValue={category.name || ""}
                    errors={errors}
                    required
                    maxLength={100}
                  />
                </div>

                <div className="">
                  <Textarea
                    title="Description"
                    inputName="description"
                    register={register}
                    defaultValue={category.description || ""}
                    errors={errors}
                    required
                    maxLength={4000}
                  />
                </div>

                <div className="flex">
                  <button
                    className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
                    onClick={() => {
                      setCategory();
                      setEditCategoryModalOpen(false);
                      reset();
                    }}
                  >
                    cancel
                  </button>

                  <button
                    className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 disabled:bg-blue-200"
                    type="submit"
                    disabled={editCategoryMutation.isLoading}
                  >
                    {editCategoryMutation.isLoading
                      ? "Processing..."
                      : "Edit Item"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      <div className="">
        <h1 className="text-center font-bold text-4xl mb-10">Categories</h1>
        {addCategoryModalOpen && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex lg:min-w-[800px]">
                <div className="mr-6">
                  <Input
                    title="Category Name"
                    inputName="name"
                    register={register}
                    errors={errors}
                    required
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="flex lg:min-w-[800px]">
                <div className="mr-6">
                  <Textarea
                    title="Description"
                    inputName="description"
                    register={register}
                    errors={errors}
                    required
                    maxLength={4000}
                  />
                </div>
              </div>

              <div className="flex">
                <button
                  className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
                  onClick={() => {
                    setAddCategoryModalOpen(false);
                    reset();
                  }}
                >
                  cancel
                </button>

                <button
                  className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 disabled:bg-blue-200"
                  type="submit"
                  disabled={createCategoryMutation.isLoading}
                >
                  {createCategoryMutation.isLoading
                    ? "Processing..."
                    : "Add Item"}
                </button>
              </div>
            </form>
          </>
        )}

        {addCategoryModalOpen ? null : (
          <button
            className="bg-blue-500 px-4 py-2 rounded-md mb-4"
            onClick={() => setAddCategoryModalOpen(true)}
          >
            Add New Category
          </button>
        )}
        {data?.length > 0 ? (
          <>
            <table className="border">
              <thead>
                <tr className="border border-bottom">
                  <th className="px-4">Name</th>
                  <th className="px-4">Description</th>
                  <th className="px-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr className="">
                    <td className="px-4">{item.name}</td>
                    <td className="px-4">{item.description}</td>
                    <td className="px-4">
                      <button
                        className="bg-blue-500 px-4 py-2 rounded-md mr-4 my-2"
                        onClick={() => {
                          setCategory(item);
                          setEditCategoryModalOpen(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-gray-500 px-4 py-2 rounded-md"
                        onClick={() => {
                          setCategory(item);
                          setDeleteModalOpen(true);
                        }}
                      >
                        Delete Category
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <div>There are no items to display</div>
        )}
      </div>
    </>
  );
}
