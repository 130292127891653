import { Outlet } from "react-router-dom";

export default function NewOrders() {
  return (
    <div>
      <h2 className="h2 text-center mb-8">New Order</h2>
      <Outlet />
    </div>
  );
}
