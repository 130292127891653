import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-colors">
      <div className="flex flex-col justify-between container mx-auto pt-20  px-12 md:flex-row lg:px-24">
        <div className="flex flex-col gap-6 mb-6">
          <h3 className="font-bold text-4xl mb-4">Storesal</h3>
          <p>
            <a href="tel:+919036099389" className="">
              +919036099389
            </a>
          </p>
          <p>
            <a href="mailto:sales.storesal@gmail.com" className="">
              sales.storesal@gmail.com
            </a>
          </p>
        </div>
        <div className="flex flex-col gap-6">
          <h3 className="font-bold text-2xl mb-4">Quick Links</h3>
          <div className="flex flex-wrap">
            <Link to="/terms" className="mr-6">
              Terms and Conditions
            </Link>
            <Link to="/prohibited-items">Prohibited Items</Link>
          </div>
        </div>
      </div>
      <hr className="container h-[2px] bg-gray-500 my-10 mx-auto" />
      <p className="text-center pb-10">
        All Rights Reserved. © Storesal Private LTD
      </p>
    </div>
  );
}
