import React, { useState } from "react";
import {
  addItemToOrder,
  adminAddCommentForItem,
  createOrder,
  deleteItemInOrder,
  deleteOrder,
  getOrders,
  submitOrder,
  userAddAddress,
} from "queries/orderQueries";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

export default function AddCommentModal({
  orderId,
  itemId,
  setOpenAddComment,
  data,
  onCancelHandler,
  onCommentSubmit,
}) {
  console.log("comment in modal");
  console.log(data);
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  return (
    <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
      <div className="bg-[#F0FEFC] p-6">
        <h1 className="mb-8 ">Add Details</h1>
        <form onSubmit={handleSubmit(onCommentSubmit)}>
          <div className="flex flex-col ">
            <label htmlFor="comment" className="text-left">
              Comment
            </label>
            <input
              className="input"
              placeholder="Add Comment"
              defaultValue={data || "Item is not available in market"}
              {...register("comment", {
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
            />
            {errors.comment && (
              <span className="text-red-500">{errors.comment.message}</span>
            )}
          </div>

          <div>
            <button
              className="bg-gray-500 px-4 py-2 rounded-md mr-6"
              onClick={onCancelHandler}
            >
              Cancel
            </button>
            <button className="bg-blue-500 px-4 py-2 rounded-md">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
