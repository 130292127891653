import Tippy from "@tippyjs/react";
import { Button, CancelButton } from "components/Button";
import { Input } from "components/Input";
import AddAddressModal from "components/modals/AddAddressModal";
import AddLocalAddressModal from "components/modals/AddLocalAddressModal";
import { errorToast } from "helpers/Toast";
import InHouseDraftPages from "pages/orders/InHouseDraftPage";
import { orderPut, submitOrder } from "queries/orderQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import "tippy.js/dist/tippy.css";

export default function DraftOrderActionButtons({ order }) {
  const location = useLocation();
  // console.log("location.state");
  // console.log(location.state);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openAddItem, setAddItem] = useState(
    (location.state && location.state.addItem) || false
  );

  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openAddLocalAddress, setOpenAddLocalAddress] = useState(false);
  const [openLocalAddressProofView, setLocalAddressProofView] = useState(false);

  //   const [selectedItemType, setSelectedItemType] = useState(
  //     location.state?.itemType
  //   );

  let checkIfBuyYourselfIsInOrder = [];
  const isBuyYourselfInOrder = order.orderType === "buyYourself";
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  // Draft mentioned in query keys is status which is sent as url parameter

  let totalBuyYourselfInOrder = 0;

  const mutationSuccessHandler = (data) => {
    console.log(data);
    reset();
    // setSelectedItemType("");
    setLocalAddressProofView("");

    queryClient.invalidateQueries("allOrders");
    queryClient.invalidateQueries("orders");
    setAddItem(false);
  };
  const deleteMutationSuccessHandler = (data) => {
    console.log(data);
    queryClient.invalidateQueries("allOrders");
    queryClient.invalidateQueries("orders");

    // setSelectedItemType("");
  };

  const updateOrderMutation = useMutation(orderPut, {
    onSuccess: (data) => {
      reset();
      mutationSuccessHandler(data);
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });
  const submitOrderMutation = useMutation(submitOrder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("orders");
      navigate("/user/orders");
    },
    onError: (error) => {
      console.log(error);
      errorToast(error);
    },
  });

  const onCancelHandler = () => {
    reset();
    // setOrderId();

    setOpenAddAddress(false);
    setOpenAddLocalAddress(false);
    setLocalAddressProofView(false);
  };

  const onSubmit = (formdata) => {
    console.log("form data to display in on Submit");
    console.log(formdata);
    updateOrderMutation.mutate({
      data: formdata,
      query: "addAddressProofImages",
    });
  };

  const isInHouseProductPresent = order.items.some(
    (item) => item.itemType === "default"
  );
  const shouldCustomerPayNow =
    (order.orderType === "buyYourself" && isInHouseProductPresent) ||
    order.orderType === "inhouse";

  return (
    <div className="">
      {/*  <button
    className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-8"
    onClick={() => deleteOrderHandler(orders._id)}
  >
    Delete Order
    </button> */}

      {isBuyYourselfInOrder && (
        <>
          {(!order.isLocalAddressProofUpdated ||
            !order.isLocalAddressUpdated) && (
            <div className="p-2 bg-green-100 rounded-md flex mb-2">
              <i>
                Local Address with identity proof is required for orders with
                items bought by customer
              </i>
            </div>
          )}
        </>
      )}

      {/* <PremiumService
    isPremiumAvailed={order.isPremiumAvailed}
    orderId={order._id}
    orderStatus={order.orderStatus}
  /> */}
      {openLocalAddressProofView && (
        <div className="flex flex-col my-6 gap-6">
          {order.isLocalAddressProofUpdated ? (
            <>
              <h2>Local Address Identity Proof</h2>
              <div className="flex gap-4">
                {order.localAddress.addressProof.map((item, index) => (
                  <img
                    src={item}
                    alt="Address Proof"
                    width={100}
                    height={100}
                    key={index}
                  />
                ))}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col">
                  <Input
                    title=""
                    inputName="addressProof"
                    inputType="file"
                    register={register}
                    errors={errors}
                    required
                    multiple
                  />
                  <input {...register("orderId")} value={order._id} hidden />

                  <div className="flex">
                    <button
                      className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
                      onClick={onCancelHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
                      type="submit"
                      disabled={updateOrderMutation.isLoading}
                    >
                      {updateOrderMutation.isLoading
                        ? "processing..."
                        : "Replace Images"}
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col">
                <Input
                  title="Local Address Identity Proof"
                  inputName="addressProof"
                  inputType="file"
                  register={register}
                  errors={errors}
                  required
                  multiple
                />
                <input {...register("orderId")} value={order._id} hidden />

                <div className="flex mt-4">
                  <CancelButton onClick={onCancelHandler}>Cancel</CancelButton>
                  <Button
                    type="submit"
                    disabled={updateOrderMutation.isLoading}
                  >
                    {updateOrderMutation.isLoading
                      ? "processing..."
                      : "Upload Images"}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </div>
      )}
      {isBuyYourselfInOrder && (
        <>
          <Button
            onClick={() => {
              setLocalAddressProofView(true);
            }}
            disabled={openLocalAddressProofView}
          >
            {order.isLocalAddressProofUpdated
              ? "Replace Indian Address Proof"
              : "Add Indian Address Proof"}
          </Button>
          <Button
            onClick={() => {
              setOpenAddLocalAddress(true);
            }}
          >
            {order.isLocalAddressUpdated
              ? "Edit Indian Address"
              : "Add Indian Address"}
          </Button>
          {openAddLocalAddress && (
            <AddLocalAddressModal
              onCancelHandler={onCancelHandler}
              orderId={order._id}
              setOpenAddAddress={setOpenAddLocalAddress}
              data={order.localAddress}
            />
          )}
        </>
      )}
      <Button
        onClick={() => {
          setOpenAddAddress(order._id);
        }}
      >
        {order.isAddressUpdated
          ? "Edit Shipping Address"
          : "Add Shipping Address"}
      </Button>

      {openAddAddress === order._id && (
        <AddAddressModal
          onCancelHandler={onCancelHandler}
          orderId={order._id}
          setOpenAddAddress={setOpenAddAddress}
          data={order.address}
        />
      )}

      {order.isAddressUpdated ? (
        <Button
          onClick={() => {
            isBuyYourselfInOrder
              ? shouldCustomerPayNow
                ? submitOrderMutation.mutate({
                    orderId: order._id,
                    orderStatus: "procuring",
                  })
                : submitOrderMutation.mutate({
                    orderId: order._id,
                    orderStatus: "procuring",
                  })
              : submitOrderMutation.mutate({
                  orderId: order._id,
                  orderStatus: "review",
                });
          }}
          disabled={
            isBuyYourselfInOrder
              ? order.isLocalAddressUpdated
                ? order.isLocalAddressProofUpdated
                  ? false
                  : true
                : true
              : false
          }
        >
          {isBuyYourselfInOrder
            ? shouldCustomerPayNow
              ? "Pay Now"
              : "Submit Order"
            : "Submit Order"}
        </Button>
      ) : (
        <Tippy content="Add Address details before submitting your order for review">
          <span>
            <Button
              className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl disabled:bg-cyan-200"
              onClick={() => submitOrderMutation.mutate(order._id)}
              disabled={true}
            >
              {isBuyYourselfInOrder
                ? shouldCustomerPayNow
                  ? "Pay Now"
                  : "Submit Order"
                : "Submit Order"}
            </Button>
          </span>
        </Tippy>
      )}
    </div>
  );
}
