import React, { Fragment, useState } from "react";
import AddNewItems from "components/AddNewItems";
import { Button } from "components/Button";
import {
  getAllOrders,
  userAckonwledgedReviewedItem,
} from "queries/orderQueries";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import DraftOrderActionButtons from "components/orderItems/DraftOrderActionButtons";
import ReviewOrderActionButtons from "components/orderItems/ReviewOrderActionButtons";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";

const InnerTable = ({ data, hideActions }) => {
  const queryClient = useQueryClient();
  const userAcknowledgeItemMutation = useMutation(
    userAckonwledgedReviewedItem,
    {
      onSuccess: (data) => {
        console.log(data);
        queryClient.invalidateQueries("allOrders");
        queryClient.invalidateQueries("orders");
      },
    }
  );
  return (
    <>
      <div className="container  mx-auto m-4 pb-4 bg-[#B7D9E2]">
        <table className="w-full">
          <thead className="">
            <tr className="">
              <th className="px-2 text-center capitalize">Product Name</th>
              <th className="px-2 text-center capitalize">Quantity</th>
              <th className="px-2 text-center capitalize">Description</th>
              <th className="px-2 text-center capitalize">Price</th>
              <th className="px-2 text-center capitalize">Details</th>
              {hideActions ? null : (
                <th className="px-2 text-center capitalize">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.items.map((product, index) => (
              <tr key={product._id}>
                <td className="px-2 text-center capitalize">
                  {product.name || "N/A"}
                </td>
                <td className="px-2 text-center capitalize">
                  {product.quantity || "N/A"}
                </td>
                <td className="px-2 text-center capitalize">
                  {product.description || "N/A"}
                </td>
                <td className="px-2 text-center capitalize">
                  {product.price || "N/A"}
                </td>
                <td className="px-2 text-center capitalize underline">
                  {/* {viewDetailsOpen ? (
                        <button
                          // to="/"
                          className="mr-4"
                          onClick={() => setViewDetailsOpen(false)}
                        >
                          Hide details
                        </button>
                      ) : (
                        <button
                          // to="/"
                          className="mr-4"
                          onClick={() => setViewDetailsOpen(true)}
                        >
                          View details
                        </button>
                      )} */}
                  <Link
                    className="text-[#0A2B42]"
                    to={`/user/item/${product._id}`}
                  >
                    View
                  </Link>
                </td>
                {hideActions ? null : (
                  <td className="px-2 text-center capitalize">
                    {data.orderStatus === "review" &&
                      (data.isReviewedEmailSent && !product.userAcknowledge ? (
                        <Button
                          onClick={() => {
                            const data = {
                              itemId: product._id,
                              userAcknowledge: !product.userAcknowledge,
                            };

                            userAcknowledgeItemMutation.mutate(data);
                          }}
                          disabled={!product.isProcurable}
                        >
                          {!product.userAcknowledge && "Approve Item"}
                        </Button>
                      ) : (
                        "Approved ✓"
                      ))}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InnerTable;
