import React from "react";
import Services from "assets/services.jpg";
import Ship from "assets/ship.jpg";
import LogisticsCost from "assets/logistics-cost.jpg";
import CompetitiveCost from "assets/competitive-cost.jpg";
import Mail from "assets/mail.jpg";
import Track from "assets/track.jpg";
import Reciept from "assets/invoice.png";
import Quality from "assets/quality.jpeg";
import ProductDetails from "assets/product-details.png";

const HowThisWorksData = [
  {
    id: 1,
    title: "Avail Your Services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy tex",
    image: Services,
  },
  {
    id: 2,
    title: "Fill product details",
    description: "Lorem Ipsum is simply dummy ",
    image: ProductDetails,
  },
  {
    id: 3,
    title: "Track/procure products",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy tex",
    image: Track,
  },
  {
    id: 4,
    title: "Best logistics cost",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy tex",
    image: LogisticsCost,
  },
  {
    id: 5,
    title: "Ship",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy tex",
    image: Ship,
  },
  {
    id: 6,
    title: "Confirm Reciept",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy tex",
    image: Reciept,
  },
];

const WhyStoreslData = [
  {
    id: 1,
    description:
      "We intimate our customers at every stage of the process via Mail and our portal so as to better inform our customer in our journey of procuring locally, consolidating, and shipping internationally",
    image: Mail,
  },
  {
    id: 2,
    description: "Competitive international logistic cost",
    image: CompetitiveCost,
  },
  {
    id: 3,
    description: "Quality of services and timely delivery",
    image: Quality,
  },
];

export default function WhyStoresal() {
  return (
    <section
      className="my-12 max-w-[1920px] mx-auto lg:my-24 px-12 lg:px-32"
      id="features"
    >
      <h2 className="why-heading-colors h2 text-center relative">
        How This Works
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-20">
        {HowThisWorksData.map((item) => (
          <div key={item.id}>
            <div className="flex items-center ">
              <h3 className="font-bold text-7xl text-orange-500 mr-4">
                {item.id}
              </h3>
              <h4 className="font-bold text-2xl md:text-3xl why-heading-colors">
                {item.title}
              </h4>
            </div>
            <img src={`${item.image}`} className="w-full mt-8 lg:mts-12" />
            {/* <p className="mt-4">{item.description}</p> */}
          </div>
        ))}
      </div>

      <h2 className="why-heading-colors h2 text-center mt-24">Why Storesal</h2>
      <div className="mt-16 lg:px-20 max-w-5xl	mx-auto">
        <div>
          {WhyStoreslData.map((item) => (
            <div
              className="flex flex-col-reverse sm:flex-row odd:sm:flex-row-reverse mt-12 gap-8 justify-end items-center"
              key={item.id}
            >
              <p>{item.description}</p>
              <img src={`${item.image}`} width={300} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
