import React, { useState } from "react";
import ReactDom from "react-dom";
import {
  addItemToOrder,
  createOrder,
  deleteItemInOrder,
  deleteOrder,
  getOrders,
  itemPut,
  orderPut,
  submitOrder,
  userAddAddress,
} from "queries/orderQueries";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getUser } from "queries/userQueries";
import { Input } from "components/Input";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";

export default function AddAddressModal({
  onCancelHandler,
  orderId,
  setOpenAddAddress,
  data,
}) {
  const queryClient = useQueryClient();
  const userData = useQuery("user", getUser);
  console.log("orderId display");
  console.log(orderId);
  const [addressData, setAddressData] = useState(data?.address || "");

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const mutation = useMutation(orderPut, {
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries("allOrders");
      queryClient.invalidateQueries("orders");
      setOpenAddAddress(false);
    },
  });

  const onAddressSubmit = (formData) => {
    console.log(orderId);
    console.log("orderId");
    console.log(formData);

    const newData = { orderId: orderId, address: formData };
    mutation.mutate({ data: newData, query: "addNewShippingAddress" });
  };

  if (userData.isLoading) {
    return <div>Loading...</div>;
  }
  if (userData.isError) {
    return <div>OOPS!! Something went wrong.Try again later</div>;
  }

  const AddressForm = () => {
    return (
      <div className="bg-[#F0FEFC] p-6 ">
        <h1 className="mb-8 ">Add Shipping Address</h1>
        <form onSubmit={handleSubmit(onAddressSubmit)}>
          <div className="flex flex-col ">
            <Input
              title="Name"
              inputName="name"
              register={register}
              defaultValue={data?.name || "Ramesh A"}
              errors={errors}
              required
              maxLength={100}
            />
          </div>

          <div className="flex flex-col ">
            <Input
              title="Address Line 1"
              inputName="addressline1"
              register={register}
              placeholder="Enter your address"
              defaultValue={data?.addressline1 || "test 12"}
              errors={errors}
              required
            />
          </div>

          <div className="flex flex-col ">
            <Input
              title="Address Line 2"
              inputName="addressline2"
              register={register}
              placeholder="Enter your address"
              defaultValue={data?.addressline2 || ""}
              errors={errors}
              maxLength={100}
            />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col mr-6">
              <Input
                title="City"
                inputName="city"
                register={register}
                placeholder="Enter your City"
                defaultValue={data?.city || "Seattle"}
                errors={errors}
                required
              />
            </div>

            <div className="flex flex-col ">
              <Input
                title="State/Province"
                inputName="state"
                register={register}
                defaultValue={data?.state || "Michigan"}
                errors={errors}
                required
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col mr-6">
              <Input
                title="Country"
                inputName="country"
                register={register}
                placeholder="Enter your country"
                defaultValue={data?.country || "USA"}
                errors={errors}
                required
                maxLength={100}
              />
            </div>

            <div className="flex flex-col ">
              <Input
                title="Zip/Postal code"
                inputtype="number"
                inputName="pincode"
                register={register}
                defaultValue={data?.pincode || "15935"}
                errors={errors}
                required
              />
            </div>
          </div>
          <div className="flex flex-col ">
            <Input
              title="Telephone"
              inputName="number"
              register={register}
              placeholder="Enter your Phone Number"
              defaultValue={data?.telephone || "123546456"}
              errors={errors}
              required
            />
          </div>

          <div>
            <button
              className="bg-gray-500 px-4 py-2 rounded-md mr-6"
              onClick={onCancelHandler}
            >
              Cancel
            </button>
            <button className="bg-blue-500 px-4 py-2 rounded-md">Submit</button>
          </div>
        </form>
      </div>
    );
  };

  return ReactDom.createPortal(
    <div className="fixed w-screen top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center overflow-scroll">
      {/* {userData.data.shippingAddress?.length > 0 ? ( */}
      {/*   <div className="bg-[#F0FEFC] p-6 mt-20"> */}
      {/*     {userData.data.shippingAddress.map((item) => ( */}
      {/*       <div className="flex" onClick={() => setAddressData(item)}> */}
      {/*         <div */}
      {/*           className={ */}
      {/*             addressData?._id === item._id ? "bg-[#F0FEFC]" : "bg-white" */}
      {/*           } */}
      {/*         > */}
      {/*           {addressData._id === item._id ? ( */}
      {/*             <BiRadioCircleMarked /> */}
      {/*           ) : ( */}
      {/*             <BiRadioCircle /> */}
      {/*           )} */}
      {/*         </div> */}
      {/*         <div className="ml-4"> */}
      {/*           <h3>{data.name}</h3> */}
      {/*           <p>{data.addressline1},</p> */}
      {/*           <p>{data.addressline2}</p> */}
      {/*           <p> */}
      {/*             {data.city},{data.state},{data.country},{data.pincode} */}
      {/*           </p> */}
      {/*         </div> */}
      {/*       </div> */}
      {/*     ))} */}
      {/*     <AddressForm /> */}
      {/*   </div> */}
      {/* ) : ( */}
      <AddressForm />
    </div>,
    document.getElementById("portal")
  );
}
